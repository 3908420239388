import React from 'react';
import styled from 'styled-components';
import { Instance } from 'tinycolor2';

// Styles
const StyledList = styled.div`
    background-color: ${props => props.theme.colours.offLight};
    padding: 3px;
    margin-bottom: 10px;
    border-radius: 12px;
`;

const StyledBox = styled.div<{ borderColor: string; isAll?: boolean }>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${props => props.theme.colours.white};
    height: 42px;
    overflow: hidden;
    border-radius: 12px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.24);
    border: 1px solid black;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-color: ${props => props.borderColor};
    font-style: ${props => (props.isAll ? 'italic' : '')};
`;

type LegendItemListProps = {
    isAll?: boolean;
    colour: Instance;
    rowId?: number | string | null;
    children: React.ReactNode;
};

const LegendItemList = ({ isAll, colour, rowId, children }: LegendItemListProps) => {
    const borderColor = colour.clone().darken(10).toString();

    return (
        <StyledList key={rowId}>
            <StyledBox borderColor={borderColor} isAll={isAll}>
                {children}
            </StyledBox>
        </StyledList>
    );
};

export default LegendItemList;
