import React from 'react';
import styled from 'styled-components';

// Styles
const StyledBreadcrumbsItemContentWrapper = styled.div`
    border-radius: 0 37px 37px 0;
    background-color: ${props => props.theme.colours.white};
    padding: 6px;
    display: block;
    width: inherit;
    height: 100%;

    @media (max-width: 768px) {
        padding-left: 0;
    }
`;

const StyledBreadcrumbsItemContentCurrent = styled.div`
    border-top: 1px solid #3e3a3a;
    border-bottom: 1px solid ${props => props.theme.colours.black};
    background-color: #1e1c1c;
    color: ${props => props.theme.colours.light};

    border: none;
    line-height: 37px;
    height: 37px;
    min-width: 54px;
    position: relative;
    border-radius: 0 37px 37px 0;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 8px;
    padding-left: 24px;

    @media (max-width: 768px) {
        padding-left: 1rem;
    }
`;

type DimensionSelectBoxContentProps = {
    children: React.ReactNode;
};

const DimensionSelectBoxContent = ({ children }: DimensionSelectBoxContentProps) => {
    return (
        <StyledBreadcrumbsItemContentWrapper>
            <StyledBreadcrumbsItemContentCurrent>{children}</StyledBreadcrumbsItemContentCurrent>
        </StyledBreadcrumbsItemContentWrapper>
    );
};

export default DimensionSelectBoxContent;
