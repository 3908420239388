import { Moment } from 'moment';
import { CubedField, CubedResource } from '../../types';
import { BreadcrumbsDropdown, DimensionFilters } from '../types';
import { formatDates } from '../../helpers/format-dates';
import { ResourceParams } from '../../react-query/hooks/use-fetch-resource-report-view';

export const generateFieldOptions = (
    filters: DimensionFilters[],
    allResources: CubedResource[]
): BreadcrumbsDropdown[] => {
    const allFields: CubedField[] = [];
    let selectedFields: CubedField[] = [];

    selectedFields = filters.map(filter => filter.field);

    const dropdownOptions: BreadcrumbsDropdown[] = [];

    for (let resourceData of allResources) {
        let visibleFields = [];

        if (resourceData.dimensions !== undefined) {
            for (let field of resourceData.dimensions) {
                if (!allFields.includes(field) && !selectedFields.includes(field)) {
                    allFields.push(field);
                    const id = `${resourceData.id}-${field.rawName}`;
                    visibleFields.push({ ...field, id });
                }
            }
        }

        if (visibleFields.length > 0) {
            dropdownOptions.push({ id: resourceData.id, resource: resourceData, fields: visibleFields });
        }
    }

    return dropdownOptions;
};

export type generateDimensionProps = {
    dimensionFilters: DimensionFilters[];
    dateDimension: CubedField;
    resource?: CubedResource;
    startDate: Moment;
    endDate: Moment;
    field: CubedField;
    value: string;
};

export const generateDimensionParams = ({
    dimensionFilters,
    dateDimension,
    resource,
    startDate,
    endDate,
    field,
    value,
}: generateDimensionProps): ResourceParams[] => {
    const dateFormatted = formatDates(startDate, endDate, resource);

    const dateParams = [];
    if (dateDimension) {
        dateParams.push({ key: `${dateDimension.rawName}__gte`, value: dateFormatted.startDate });
        dateParams.push({ key: `${dateDimension.rawName}__lte`, value: dateFormatted.endDate });
    }

    let key = `${field.rawName}${field.lookupTerm ? '__' + field.lookupTerm : ''}`;

    let newFilters: { key: string; value: string | number | Date }[] = [];
    dimensionFilters.every((filter: DimensionFilters) => {
        if (field.rawName === filter.field.rawName) {
            return false;
        }
        let key = `${filter.field.rawName}${filter.field.lookupTerm ? '__' + filter.field.lookupTerm : ''}`;
        newFilters.push({ key: key, value: filter.value });
        return true;
    });

    const params = [
        ...newFilters,
        { key: `${key}__icontains`, value: value },
        { key: 'group_by', value: field.rawName },
        ...dateParams,
    ];

    return params as ResourceParams[];
};
