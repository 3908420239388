import React from 'react';
import styled from 'styled-components';

// Types
import { CubedField, CubedResource } from '../../../../types';
import { DimensionFilters } from '../../../types';

// Context
import { useBreadcrumbContext } from '../context/breadcrumb-context';

// Styles
const StyledDropdownItem = styled.li`
    position: relative;
    cursor: pointer;
    width: inherit;
    background-color: ${props => props.theme.colours.hoverGrey};
    padding: 4px 12px;
    border-radius: 3px;
    margin: 2px;
    transition: ${props => props.theme.transition};

    &:hover {
        background-color: #dfdfdf;
    }
`;

type DimensionSelectBoxOptionsFieldsProps = {
    resource: CubedResource;
    field: CubedField;
    handleSetResourceAndField: (resource: CubedResource, field: CubedField, filter?: DimensionFilters) => void;
    children: React.ReactNode;
};

const DimensionSelectBoxOptionsFields = ({
    resource,
    field,
    handleSetResourceAndField,
    children,
}: DimensionSelectBoxOptionsFieldsProps) => {
    const { setExpendFieldOptions } = useBreadcrumbContext();

    const handleBreadcrumbItemOnClick = (resource: CubedResource, field: CubedField) => {
        handleSetResourceAndField(resource, field);
        setExpendFieldOptions(false);
    };

    return (
        <StyledDropdownItem onClick={() => handleBreadcrumbItemOnClick(resource, field)}>{children}</StyledDropdownItem>
    );
};

export default DimensionSelectBoxOptionsFields;
