import React from 'react';
import styled from 'styled-components';

// Styles
const StyledBreadcrumbsItem = styled.div`
    position: relative;
    box-sizing: border-box;
    float: right;
    margin-left: -20px;
    z-index: 1;

    @media (max-width: 768px) {
        margin-left: 0;
    }
`;

type DimensionSearchBoxContentProps = { children?: React.ReactNode };

const DimensionSearchBoxContent = ({ children }: DimensionSearchBoxContentProps) => (
    <StyledBreadcrumbsItem>{children}</StyledBreadcrumbsItem>
);
export default DimensionSearchBoxContent;
