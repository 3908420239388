import React from 'react';
import styled from 'styled-components';
import { Instance } from 'tinycolor2';

// Styles
const StyledTextSpan = styled.span`
    padding: 0 6px;
    overflow: hidden;
    max-width: 210px;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    margin-left: 5px;
    line-height: 43px;
    font-size: 14px;
`;

const StyledColourSpan = styled.span<{ backgroundColor: string }>`
    width: 42px;
    height: 42px;
    background-color: ${props => props.backgroundColor};
`;

const StyledContentContainer = styled.div`
    display: flex;
    align-items: center;
`;

type LegendItemListContentProps = {
    colour: Instance;
    text: string | number;
};

const LegendItemListContent = ({ colour, text }: LegendItemListContentProps) => {
    const backgroundColor = colour.clone().toString();

    return (
        <StyledContentContainer>
            <StyledColourSpan backgroundColor={backgroundColor} />
            <StyledTextSpan>{text}</StyledTextSpan>
        </StyledContentContainer>
    );
};

export default LegendItemListContent;
