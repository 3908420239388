import { Moment } from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { cloneDeep } from 'lodash';

// Redux
import store from '../../redux/store';

// Helpers
import { formatDimensionFilters } from '../../helpers/dimension/dimension-helper';
import { formatDates } from '../../helpers/format-dates';

// Types
import {
    DataTableCellProperties,
    DataTableColumn,
    DataTableMetaObject,
    DataTablePaginationListItem,
    DataTableTotals,
    DimensionChildren,
    DimensionFilters,
    OrderByDir,
    ReportConfig,
} from '../types';
import { CubedField, CubedFieldHeatmap, CubedResource } from '../../types';
import { ConfigDataSuccess } from '../../react-query/types';
import { MetricsFilter, Product } from '../../filter-bar/types';

export const handleCalculateHeatmap = (value: number, ranges: CubedFieldHeatmap) => {
    if (!ranges) {
        return;
    }

    if (ranges.default && value === ranges.default[0]) {
        return ranges.default[1];
    }

    for (let rangeName in ranges) {
        var range = ranges[rangeName as keyof CubedFieldHeatmap];
        if (range && value >= range[0] && value <= (range[1] as number)) return rangeName;
    }

    // fail safe
    return ranges.default![1];
};

export const addCheckboxColumn = (columns: DataTableColumn[]): DataTableColumn[] => {
    const checkboxColumn: DataTableColumn = {
        id: 'checkbox',
        rawName: 'checkbox',
        displayName: '',
        metricWidth: '50px',
        dataType: 0,
    };

    const hasCheckboxColumn = columns.some(column => column.id === 'checkbox');

    if (!hasCheckboxColumn) {
        return [checkboxColumn, ...columns];
    }

    return columns;
};

export const formatSearchField = (field: CubedField, value: string) => {
    const obj = {
        key: `${field.rawName}${field.lookupTerm ? '__' + field.lookupTerm : ''}__icontains`,
        value: value,
    };
    return { ...obj };
};

export type generateTableParamsProps = {
    config: ReportConfig;
    dimensionFilters: DimensionFilters[];
    page: number;
    resource: CubedResource;
    rowCount: number;
    searchValue: string;
    selectedProducts: Product[];
    tableGroupBy: CubedField[];
    tableOrderBy: CubedField;
    tableOrderDir: OrderByDir;
    startDate: Moment;
    endDate: Moment;
    selectedMetrics: MetricsFilter[];
};

export const generateTableParams = ({
    config,
    dimensionFilters,
    page,
    resource,
    rowCount,
    searchValue,
    selectedProducts,
    tableGroupBy,
    tableOrderBy,
    tableOrderDir,
    startDate,
    endDate,
    selectedMetrics,
}: generateTableParamsProps) => {
    const searchObject = { ...formatSearchField(tableGroupBy[0], searchValue) };
    const orderDirModifier = tableOrderDir === 'desc' ? '-' : '';
    const dateParams = [];
    let metricParams: string[] = [];
    const dateFormatted = formatDates(startDate, endDate, resource);

    if (resource.dateDimension) {
        dateParams.push({
            key: `${resource.dateDimension.rawName}__gte`,
            value: dateFormatted.startDate,
        });
        dateParams.push({
            key: `${resource.dateDimension.rawName}__lte`,
            value: dateFormatted.endDate,
        });
    }

    const productParams = config.ignoreProducts
        ? []
        : [
              {
                  key: 'product__in',
                  value: selectedProducts.map(product => product.id).toString(),
              },
          ];

    selectedMetrics &&
        selectedMetrics.forEach(metricFilter => {
            if (metricFilter.status === 'view') {
                metricParams = [
                    ...metricParams,
                    metricFilter.metricType === 9
                        ? `${metricFilter.metric.rawName}=${metricFilter.value === 'true' ? 1 : 0}`
                        : `${metricFilter.metric.rawName}__${metricFilter.operator.name}=${metricFilter.value}`,
                ];
            }
        });

    const params = [
        ...formatDimensionFilters(dimensionFilters, resource),
        ...dateParams,
        ...productParams,
        searchObject,
        {
            key: 'group_by',
            value: tableGroupBy.map(group => group.rawName).toString(),
        },
        {
            key: 'order_by',
            value: orderDirModifier + tableOrderBy.rawName,
        },
        {
            key: 'limit',
            value: rowCount,
        },
        {
            key: 'offset',
            value: rowCount * (page - 1),
        },
    ];

    if (metricParams.length > 0) {
        params.push({
            key: 'having',
            value: metricParams.toString(),
        });
    }

    return params;
};

export const formatTotals = (
    dimensions: CubedField[],
    metrics: CubedField[],
    displayData: ConfigDataSuccess
): DataTableTotals[] => {
    const dimensionTotals = dimensions.map((dimension, index) => {
        return {
            key: uuidv4(),
            displayData: index === 0 ? 'Totals:' : '    ',
            isDimension: true,
            column: dimension,
        };
    });
    const metricTotals = metrics.map(metric => {
        if (displayData.meta.totals && !displayData.meta.totals[metric.rawName]) {
            return {
                key: uuidv4(),
                displayData: 'NA',
                column: metric,
            };
        }
        return {
            key: uuidv4(),
            displayData:
                displayData.meta && displayData.meta.totals ? displayData.meta.totals[metric.rawName].value : '',
            column: metric,
        };
    }, this);

    return [...dimensionTotals, ...metricTotals];
};

export const getTotals = (data: ConfigDataSuccess, tableGroupBy: CubedField[], metrics: CubedField[]) => {
    let totals: DataTableTotals[] = [];
    if (data.meta && data.meta.hasOwnProperty('totals')) {
        totals = formatTotals(tableGroupBy, metrics, data);
    }

    return totals;
};

export const calculateProportion = (column: CubedField, totals: DataTableTotals[], dataObject: ConfigDataSuccess) => {
    const currencySymbol = store.getState().currency.symbol;
    const newData = dataObject;
    const newObjects = cloneDeep(newData.objects);

    if (newData.objects) {
        newObjects.forEach(row => {
            let colTotal;
            let proportion;

            totals.forEach(total => {
                if (total.column.rawName === column.rawName) {
                    colTotal = parseFloat(total.displayData.replace(currencySymbol, '').replace(/,/g, ''));
                }
            });

            if (colTotal && colTotal > 0) {
                proportion = ((row[column.rawName].raw_value / colTotal) * 100).toFixed(2);
            }
            if (proportion && !isNaN(parseFloat(proportion))) {
                row[column.rawName].proportion = proportion;
            }
        });
    }

    newData.objects = newObjects;

    return newData;
};

export const getProportions = (
    data: ConfigDataSuccess,
    totals: DataTableTotals[],
    tableGroupBy: CubedField[],
    metrics: CubedField[]
) => {
    let dataWithProportions = data;
    const copiedData = { ...data };
    const columns = [...tableGroupBy, ...metrics];

    if (columns.length > 0) {
        columns.forEach(column => {
            if (column.displayProportion) {
                dataWithProportions = calculateProportion(column, totals, copiedData);
            }
        });
    }

    return dataWithProportions;
};

export const findChildDimension = (children: DimensionChildren, value: string, filters: DimensionFilters[]) => {
    const childDimension = children[value] || children['default'];
    const filterFields = filters.map(filter => filter.field);

    for (let result of childDimension) {
        if (!filterFields.includes(result.field)) return result;
    }

    return null;
};

export const getNextDimension = (
    field: DataTableColumn,
    data: DataTableCellProperties,
    resource: CubedResource,
    dimensionFilters: DimensionFilters[]
) => {
    if (field.dataOverrides) {
        for (let override of field.dataOverrides) {
            if (override.condition(data[override.property])) {
                return { resource: override['resource'], field: override['field'] };
            }
        }
    }

    if (field.parentOverride) {
        const currentResource = resource;
        const filters = dimensionFilters.filter(filter => currentResource.dimensions?.includes(filter.field));
        return findChildDimension(field.parentOverride.children as DimensionChildren, filters[0]?.value as string, [
            ...dimensionFilters,
        ]);
    } else {
        return findChildDimension(field.children as DimensionChildren, data.value as string, [...dimensionFilters]);
    }
};

export const getColumnTotal = (tableTotalData: DataTableMetaObject | null, column: DataTableColumn) => {
    if (!tableTotalData) {
        return '';
    }

    if (column.rawName === 'checkbox') {
        return '';
    }

    if (column.isDimension) {
        return 'Totals:';
    }

    return tableTotalData.totals?.[column.rawName]?.value || '';
};

export const getMaxPage = (totalResults: number, currentRowCount: number): number => {
    return Math.ceil(totalResults / currentRowCount);
};

export const initialiseButtonSides = (currentPage: number, maxPage: number, idealSideLength: number) => {
    let prePages = [];
    let postPages = [];

    for (let i = 1; i <= idealSideLength; i++) {
        if (currentPage - i >= 1) {
            prePages.unshift({ pageNumber: currentPage - i });
        }
        if (currentPage + i <= maxPage) {
            postPages.push({ pageNumber: currentPage + i });
        }
    }

    if (prePages.length < idealSideLength) {
        let postPageCount = postPages.length;
        for (let i = 1; i <= idealSideLength - prePages.length; i++) {
            postPages.push({ pageNumber: currentPage + i + postPageCount });
        }
    }

    if (postPages.length < idealSideLength) {
        let prePageCount = prePages.length;
        for (let i = 1; i <= idealSideLength - postPages.length; i++) {
            prePages.unshift({ pageNumber: currentPage - i - prePageCount });
        }
    }

    if (prePages.length > 0) prePages[0] = { pageNumber: 1 };
    if (prePages[1] && prePages[1]['pageNumber'] > 2) prePages[1] = {};
    if (postPages.length > 0) postPages[postPages.length - 1] = { pageNumber: maxPage };
    if (postPages[postPages.length - 2] && postPages[postPages.length - 2]['pageNumber'] < maxPage - 1)
        postPages[postPages.length - 2] = {};

    return [prePages, postPages];
};

export const generateTablePaginationsList = (
    currentPageState: number,
    maxButtons: number,
    minPage: number,
    totalResults: number,
    currentRowCount: number
): DataTablePaginationListItem[] => {
    const maxPage = getMaxPage(totalResults, currentRowCount);
    const idealSideLength = Math.floor(maxButtons / 2);
    let pages = [];

    if (maxPage === 1) {
        pages = [{ pageNumber: 1, isCurrent: true }];
    } else if (maxPage < maxButtons) {
        pages = Array.from({ length: maxPage - minPage + 1 }, (_, index) => ({
            pageNumber: index + 1,
            isCurrent: currentPageState === index + 1,
        }));
    } else {
        const [prePages, postPages] = initialiseButtonSides(currentPageState, maxPage, idealSideLength);
        pages = [...prePages, { pageNumber: currentPageState, isCurrent: true }, ...postPages];
    }

    return pages as DataTablePaginationListItem[];
};
