import React from 'react';
import styled from 'styled-components';

import { useBreadcrumbDimensionSearchContext } from '../context/breadcrumb-dimension-search-context';
import DimensionSearchBoxOptionsLoading from './dimension-search-box-options-loading';
import DimensionSearchBoxOptionsEmpty from './dimension-search-box-options-empty';
import DimensionSearchBoxOptionsList from './dimension-search-box-options-list';

// Styles
const StyledBreadcrumbsItemDropdown = styled.div`
    ${props => props.theme.boxShadow(1)}
    position: absolute;
    background-color: ${props => props.theme.colours.textLight};
    transition: ${props => props.theme.transition};
    z-index: 1000;
    width: 100%;
    padding: 6px;
    overflow: hidden;

    visibility: visible;
    max-height: 600px;
`;

const DimensionSearchBoxOptions = () => {
    const { dimensionOptionsRef, expendDimensionOptions, dimensionOptionsDataQuery, dimensionOptions } =
        useBreadcrumbDimensionSearchContext();

    return (
        <>
            {expendDimensionOptions && (
                <StyledBreadcrumbsItemDropdown ref={dimensionOptionsRef}>
                    <ul>
                        {dimensionOptionsDataQuery.status === 'pending' && <DimensionSearchBoxOptionsLoading />}

                        {dimensionOptionsDataQuery.status === 'success' && dimensionOptions.length === 0 && (
                            <DimensionSearchBoxOptionsEmpty />
                        )}

                        {dimensionOptionsDataQuery.status === 'success' && <DimensionSearchBoxOptionsList />}
                    </ul>
                </StyledBreadcrumbsItemDropdown>
            )}
        </>
    );
};

export default DimensionSearchBoxOptions;
