import { v4 as uuidv4 } from 'uuid';
import { ResourceDataObjectValue, ResourceDataReportViewObject } from '../../react-query/types';

export const mapObject = (object: any) => {
    const values: Record<string, ResourceDataObjectValue> = {};
    Object.keys(object).forEach(key => {
        const value = object[key];

        if (key === 'id') {
            values[key] = {
                rawValue: value,
                value: value,
            };
        } else {
            values[key] = {
                rawValue: value.raw_value,
                value: value.value,
            };
        }
    });

    return {
        __id: uuidv4(),
        values: values,
    };
};

export const mapObjectReportView = <T>(data: ResourceDataReportViewObject<T>[]) => {
    return data.map(item => ({
        __id: uuidv4(),
        ...item,
    }));
};
