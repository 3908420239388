/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import moment from 'moment';

// Highcharts
import Highcharts from 'highcharts';
import AnnotationsModule from 'highcharts/modules/annotations';

// Components
import CommonHighcharts from '../../../../components/common/common-highcharts';

// Types
import { ChartConfig, ChartDateGranularityTypes, ChartSeriesData, ExtendedAnnotationConfigItem } from '../../../types';
import { CubedField } from '../../../../types';
import dataTypes from '../../../../filter-bar/enums/data-types';

// Context
import { useGraphContext } from '../context/graph-context';

// Helpers
import { setTickInterval } from '../../../helpers/graph-helper';
import { formatAnnotationsChartConfig } from '../../../helpers/annotation-helper';

type LineGraphProps = {
    chart: ChartConfig;
    chartXMetric: CubedField;
    chartYMetrics: CubedField[];
    data: ChartSeriesData[];
    chartDateGranularity: ChartDateGranularityTypes;
};

AnnotationsModule(Highcharts);

const LineGraph = ({ chart, chartXMetric, chartYMetrics, data, chartDateGranularity }: LineGraphProps) => {
    const { status, isFetching, empty, startDate, endDate, annotations } = useGraphContext();

    const [tick, setTick] = useState(0);
    const [annotationConfig, setAnnotationConfig] = useState<ExtendedAnnotationConfigItem[]>([]);

    useEffect(() => {
        setAnnotationConfig(formatAnnotationsChartConfig(annotations));
    }, [annotations]);

    useEffect(() => {
        setTick(
            setTickInterval({
                chart,
                xMetric: chartXMetric,
                data,
                chartDateGranularity,
                startDate: moment(startDate),
                endDate: moment(endDate),
            })
        );
    }, [data]);

    const options = {
        ...chart?.options,
        xAxis: {
            ...chart?.options?.xAxis,
            tickInterval: tick,
            labels: {
                enabled: true,
            },
            type: [dataTypes.DATE, dataTypes.DATE_TIME].includes(chartXMetric.dataType) ? 'datetime' : 'linear',
        },
        yAxis: {
            ...chart?.options?.yAxis,
            reversed: chartYMetrics && chartYMetrics.length > 0 && chartYMetrics[0].flipYAxis ? true : false,
        },
    };

    const config = {
        ...options,
        series: data,
        annotations: annotationConfig,
    };

    if (status === 'success' && !isFetching && !empty) {
        return (
            <CommonHighcharts
                highcharts={Highcharts}
                options={{ ...config }}
                immutable={false}
                containerProps={{ style: { height: '100%', width: '100%' } }}
            />
        );
    }

    return null;
};

export default LineGraph;
