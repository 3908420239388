import React, { memo } from 'react';
import * as Checkbox from '@radix-ui/react-checkbox';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash';

import { useTableContext } from '../../context/table-context';
import { useReportViewContext } from '../../../../context/report-view-context';

import IconCheck from '../../../../../components/icons/check';

const StyledStickyColumn = css`
    position: sticky !important;
    left: 0;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    z-index: 2;
`;

const StyledCellCheckbox = styled('td')<{ scrollPosX: number }>`
    ${props => props.scrollPosX > 0 && StyledStickyColumn};

    ${props => props.theme.no_select()};
    line-height: 17px;
    font-size: 13px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    transition: 0.2s ease-in-out !important;
    background-color: ${props => props.theme.colours.white};
    background-clip: padding-box;
    position: relative;
    cursor: pointer;

    max-width: 30px;
    min-width: 30px;
    width: 30px;

    &:hover {
        background-color: ${props => props.theme.colours.hoverGrey} !important;
    }
`;

const StyledCheckboxContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;

const StyledIconCheck = styled(IconCheck)<{ checked: boolean }>`
    height: 12px;
    width: auto;
    & path {
        fill: ${props =>
            props.checked ? props.theme.colours.white : props.theme.sectionDashboard.widget.menu.textColor};
    }
`;

const StyledCheckbox = styled(Checkbox.Root)<{ disabled: boolean; checked: boolean }>`
    width: 18px;
    height: 18px;
    background-color: ${props =>
        props.checked
            ? props.theme.sectionDashboard.widget.menu.textColor
            : props.theme.sectionDashboard.widget.menu.separatorColor};
    border: 2px solid
        ${props =>
            props.disabled
                ? props.theme.colours.midGrey
                : props.checked
                ? props.theme.sectionDashboard.widget.menu.backgroundHover
                : props.theme.sectionDashboard.widget.menu.textColor};
    border-radius: 5px;
    display: flex;
    padding-top: 2px;
    justify-content: center;
    align-items: center;

    &:hover {
        border-color: ${props => props.checked && props.theme.colours.black};
        background-color: ${props => props.checked && props.theme.colours.black};

        & path {
            fill: ${props => props.theme.colours.white};
        }
    }
`;

type CellCheckboxProps = {
    rowId: string;
    checked: boolean;
    onCheckedChange: (rowId: string) => void;
};

const CellCheckbox = ({ rowId, checked, onCheckedChange }: CellCheckboxProps) => {
    const { status, isFetching, tableDisabled } = useTableContext();
    const { scrollPosX } = useReportViewContext();

    if (status === 'success') {
        return (
            <StyledCellCheckbox scrollPosX={scrollPosX} data-testid="table-body-cell">
                <StyledCheckboxContainer>
                    <StyledCheckbox
                        disabled={tableDisabled || isFetching}
                        checked={checked}
                        onCheckedChange={() => onCheckedChange(rowId)}
                        data-testid="table-cell-checkbox"
                    >
                        <Checkbox.Indicator>
                            <StyledIconCheck checked={checked} />
                        </Checkbox.Indicator>
                    </StyledCheckbox>
                </StyledCheckboxContainer>
            </StyledCellCheckbox>
        );
    }

    return null;
};

export default memo(CellCheckbox, isEqual);
