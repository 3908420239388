import React from 'react';
import styled from 'styled-components';

// Styles
const StyledDropdown = styled.div`
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 4px;
    text-transform: uppercase;
    color: #0a0a0a;
    padding-left: 0 important;
`;

type DimensionSelectBoxOptionsResourcesProps = {
    children: React.ReactNode;
};

const DimensionSelectBoxOptionsResources = ({ children }: DimensionSelectBoxOptionsResourcesProps) => {
    return <StyledDropdown>{children}</StyledDropdown>;
};

export default DimensionSelectBoxOptionsResources;
