import React from 'react';
import styled from 'styled-components';
import { QueryStatus } from '@tanstack/react-query';

// Context
import { useTableContext } from '../context/table-context';
import { useReportViewContext } from '../../../context/report-view-context';

// Component
import Message from '../../../../components/message';

// Widget
import WidgetBaseLoader from '../../../../section-dashboard/widgets/base/widget-base-loader';

const StyledContainer = styled.div<{ maxHeight?: string; status: QueryStatus; empty: boolean }>`
    max-height: ${props => props.maxHeight};
    max-width: 100%;
    overflow: ${props => (props.status !== 'success' || props.empty ? 'hidden' : 'auto')};
    border: ${props => `1px solid ${props.theme.colours.borderGrey}`};
    ${props => props.theme.scrollbar(8, 8)}
`;

const StyledTable = styled.table`
    width: 100%;
    background-color: #ffffff;
    border-collapse: separate;
`;

const StyledStatusContainer = styled.div<{ maxHeight?: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -20px;
    background-color: #ffffff;
    width: 100%;
    min-height: 200px;
    height: ${props => (props.maxHeight ? props.maxHeight : '100%')};
`;

const StyledMessageContainer = styled.div`
    max-width: 500px;
`;

type TableWrapperProps = {
    maxHeight?: string;
    emptyMessage?: string;
    children: React.ReactNode;
};

const TableWrapper = ({ maxHeight = '100%', emptyMessage = 'No data available.', children }: TableWrapperProps) => {
    const { status, empty } = useTableContext();
    const { handleOnScroll } = useReportViewContext();

    return (
        <StyledContainer maxHeight={maxHeight} status={status} empty={empty} onScroll={handleOnScroll}>
            <StyledTable>{children}</StyledTable>

            {status === 'pending' ? (
                <StyledStatusContainer maxHeight={maxHeight}>
                    <div>
                        <WidgetBaseLoader width={40} height={40} margin={0} />
                    </div>
                </StyledStatusContainer>
            ) : null}

            {status === 'error' ? (
                <StyledMessageContainer>
                    <Message
                        type="error"
                        size="small"
                        copy="There was an error retrieving your data. Please try again later or contact support@cubed.email"
                    />
                </StyledMessageContainer>
            ) : null}

            {empty && status !== 'pending' && (
                <StyledStatusContainer maxHeight={maxHeight}>
                    <StyledMessageContainer>
                        <Message type="info" size="small" copy={emptyMessage} />
                    </StyledMessageContainer>
                </StyledStatusContainer>
            )}
        </StyledContainer>
    );
};

export default TableWrapper;
