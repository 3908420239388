import React, { Component } from 'react';
import { Moment } from 'moment';

import ReportViewLayoutWrapper from '../report-view/report-view-layout';
import { config } from '../configurations/ppc-impression-share';
import { ReportConfig } from '../report-view/types';

interface LayoutPPCImpressionSharePerformanceProps {
    startDate: Moment;
    endDate: Moment;
    selectedProducts: [];
    selectedMetrics: [];
}

class LayoutPPCImpressionSharePerformance extends Component<LayoutPPCImpressionSharePerformanceProps> {
    config: typeof config;

    constructor(props: LayoutPPCImpressionSharePerformanceProps) {
        super(props);
        this.config = config;
    }

    render() {
        return (
            <ReportViewLayoutWrapper
                config={this.config as ReportConfig}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedProducts={this.props.selectedProducts}
                selectedMetrics={this.props.selectedMetrics}
            />
        );
    }
}

export default LayoutPPCImpressionSharePerformance;
