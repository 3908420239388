import React, { Component } from 'react';

import ReportViewLayoutWrapper from '../report-view/report-view-layout';
import { config } from './../configurations/seo-keyword-prioritisation';

class ReportSEOKeyword extends Component {
    constructor(props) {
        super(props);
        this.fetchTableData = this.fetchTableData.bind(this);
    }

    fetchTableData() {
        const resource = this.state.resource;
        const fetchPromise = new Request();
        for (let promise of this.tablePromises) {
            promise.cancelRequest('aborted');
        }
        this.tablePromises.push(fetchPromise);
        this.setState({ tableNoData: false, tableLoading: true, selectedRows: [] });
        fetchPromise
            .get(resource.category, resource.id, this.generateTableParams())
            .then(data => {
                let totalCount = data.data.meta ? data.data.meta.total_count : 0;
                this.setState({ tableData: data, tableLoading: false, tableTotals: totalCount });
            })
            .catch(error => {
                if (error.message !== 'aborted') {
                    console.log(error.message);
                    this.setState({
                        tableNoData: true,
                    });
                }
            });
    }
    render() {
        return (
            <ReportViewLayoutWrapper
                config={config}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedProducts={this.props.selectedProducts}
                selectedMetrics={this.props.selectedMetrics}
            />
        );
    }
}

export default ReportSEOKeyword;
