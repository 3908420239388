/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

// Highcharts
import Highcharts from 'highcharts';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers/core';

// Components
import CommonHighcharts from '../../../../components/common/common-highcharts';

// Types
import { BarChartData, ChartConfig, DataTableColumn, DataTableDataObject, DataTableSelectedRows } from '../../../types';
import { CubedField } from '../../../../types';

// Context
import { useGraphContext } from '../context/graph-context';

// Helpers
import { sortChartData, updateChartTooltipPointFormat } from '../../../helpers/graph-helper';
import { assignColour } from '../../../../helpers/colours';

type BarGraphProps = {
    chart: ChartConfig;
    chartYMetrics: CubedField[];
    data: DataTableDataObject[];
    dimensions: DataTableColumn[];
    metrics: DataTableColumn[];
    selectedRows: DataTableSelectedRows[];
};

const BarGraph = ({ chart, chartYMetrics, data, dimensions, metrics, selectedRows }: BarGraphProps) => {
    const { status, isFetching, empty } = useGraphContext();

    const currencySymbol = useSelector((state: RootState) => state.user?.symbol);

    const columns = [...dimensions, ...metrics];

    const [options, setOptions] = useState<Highcharts.Options>(chart.options);

    useEffect(() => {
        if (chart) {
            getBarChartData();
        }
    }, [data, chartYMetrics, selectedRows, chart.chartType]);

    const getBarChartData = () => {
        const barData: BarChartData[] = [];

        if (selectedRows.length === 0) {
            columns.forEach(column => {
                if (data.length > 0) {
                    data.map(row => {
                        if (column.children && row[column.rawName] !== undefined) {
                            updateChartTooltipPointFormat(chart, row[chartYMetrics[0].rawName]?.value);
                            const barValue = (row[chartYMetrics[0].rawName]?.value as string).replaceAll(',', '');

                            barData.push({
                                name: String(row[column.rawName].value),
                                y: parseFloat(barValue.replace(currencySymbol as string, '')),
                                color: assignColour(row, dimensions, selectedRows)._originalInput,
                            });
                        }

                        return barData;
                    });
                }

                return barData;
            });
        }

        if (selectedRows.length > 0) {
            selectedRows.forEach(row => {
                updateChartTooltipPointFormat(chart, row.data[chartYMetrics[0].rawName]?.value);
                const barValue = (row.data[chartYMetrics[0].rawName]?.value as string).replaceAll(',', '');

                barData.push({
                    name: String(row.data[dimensions[0].rawName].value),
                    y: parseFloat(barValue.replace(currencySymbol as string, '')),
                    color: row.colour?.getOriginalInput() as string,
                });
            });
        }

        const dataSorted = barData.sort(sortChartData);

        setOptions({
            ...chart.options,
            chart: {
                ...chart.options.chart,
                inverted: chart.chartType === 'barHorizontal' ? true : false,
            },
            series: [
                {
                    ...(chart.options.series ? chart.options.series[0] : {}),
                    data: dataSorted.slice(0, 10) as Highcharts.SeriesBarOptions['data'],
                } as Highcharts.SeriesBarOptions,
            ],
        });
    };

    if (status === 'success' && !isFetching && !empty) {
        return (
            <CommonHighcharts
                highcharts={Highcharts}
                options={{ ...options }}
                containerProps={{ style: { height: '100%', width: '100%' } }}
            />
        );
    }

    return null;
};

export default BarGraph;
