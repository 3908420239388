import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

// Types
import { ChartDateGranularityTypes } from '../../../../types';

export type FilterRadioGroupProps = {
    value: ChartDateGranularityTypes;
    onValueChange: (type: string) => void;
    children: React.ReactNode;
};

const FilterRadioGroup = ({ value, onValueChange, children }: FilterRadioGroupProps) => {
    return (
        <DropdownMenu.RadioGroup value={value} onValueChange={onValueChange}>
            {children}
        </DropdownMenu.RadioGroup>
    );
};

export default FilterRadioGroup;
