import React from 'react';
import styled from 'styled-components';

// Context
import { useTableContext } from '../../context/table-context';

// Styles
const StyledPaginationDropdownSpan = styled.span`
    margin-right: 22px;
    margin-left: -8px;
`;

// Types
export type DropdownProps = {
    children: React.ReactNode;
};

const Dropdown = ({ children }: DropdownProps) => {
    const { status } = useTableContext();

    if (status === 'success') {
        return <StyledPaginationDropdownSpan>{children}</StyledPaginationDropdownSpan>;
    }

    return null;
};

export default Dropdown;
