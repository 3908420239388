/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { UseQueryResult } from '@tanstack/react-query';
import { debounce } from 'lodash';
import { Moment } from 'moment';

// Hooks
import UseComponentVisible from '../../../../hooks/use-component-visible';

// Types
import { BreadcrumbDimensionOptions, DimensionFilters } from '../../../types';
import { CubedField, CubedResource } from '../../../../types';

// Helpers
import { generateDimensionParams } from '../../../helpers/breadcrumb-helpers';

// React Query
import { ConfigDataSuccess } from '../../../../react-query/types';
import useFetchResourceReportView, {
    ResourceParams,
} from '../../../../react-query/hooks/use-fetch-resource-report-view';

export type BreadcrumbDimensionSearchContextValues = {
    filter: DimensionFilters;
    startDate: Moment;
    endDate: Moment;
    dimensionFilters: DimensionFilters[];
    resource: CubedResource;
    setDimensionFilters: (filters: DimensionFilters[]) => void;
    setTableGroupBy: (field: CubedField[]) => void;
    handleRemoveFilter: (field: CubedField) => void;
    dimensionOptionsRef: React.MutableRefObject<null>;
    expendDimensionOptions: boolean;
    setExpendDimensionOptions: React.Dispatch<React.SetStateAction<boolean>>;
    dimensionOptionsDataQuery: UseQueryResult<ConfigDataSuccess, Error>;
    dimensionOptions: BreadcrumbDimensionOptions[];
    currentFilter: string;
    handleOnFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
    handleOnBlur: () => void;
    handleOnClick: () => void;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelect: (value: string) => void;
};

const BreadcrumbDimensionSearchContext = createContext<BreadcrumbDimensionSearchContextValues | undefined>(undefined);

export const useBreadcrumbDimensionSearchContext = () => {
    const context = useContext(BreadcrumbDimensionSearchContext);
    if (context === undefined) {
        throw new Error(
            '"Breadcrumb Dimension Search context" child components cannot be rendered outside of the Breadcrumb component'
        );
    }

    return context;
};

type BreadcrumbDimensionSearchProviderProps = {
    filter: DimensionFilters;
    startDate: Moment;
    endDate: Moment;
    dimensionFilters: DimensionFilters[];
    resource: CubedResource;
    setDimensionFilters: (filters: DimensionFilters[]) => void;
    setTableGroupBy: (field: CubedField[]) => void;
    handleRemoveFilter: (field: CubedField) => void;
    children: React.ReactNode;
};

export const BreadcrumbDimensionSearchProvider = ({
    filter,
    startDate,
    endDate,
    dimensionFilters,
    resource,
    setDimensionFilters,
    setTableGroupBy,
    handleRemoveFilter,
    children,
}: BreadcrumbDimensionSearchProviderProps) => {
    const {
        ref: dimensionOptionsRef,
        isComponentVisible: expendDimensionOptions,
        setIsComponentVisible: setExpendDimensionOptions,
    } = UseComponentVisible(false);

    const [previousFilter, setPreviousFilter] = useState('');
    const [currentFilter, setCurrentFilter] = useState('');
    const [dimensionOptions, setDimensionOptions] = useState<BreadcrumbDimensionOptions[]>([]);
    const [breadcrumbsDimensionParams, setBreadcrumbsDimensionParams] = useState<ResourceParams[]>([]);

    const dimensionOptionsDataQuery = useFetchResourceReportView({
        resource: resource,
        enabled: expendDimensionOptions,
        params: breadcrumbsDimensionParams,
        select: (data: ConfigDataSuccess) => {
            return data;
        },
    });

    // Fetch dimension options
    useEffect(() => {
        if (dimensionOptionsDataQuery.data) {
            if (dimensionOptionsDataQuery.data) {
                setDimensionOptions(dimensionOptionsDataQuery.data.objects as unknown as BreadcrumbDimensionOptions[]);
            }
        }
    }, [dimensionOptionsDataQuery.data]);

    // Set dimension options
    useEffect(() => {
        // currentFilter is what we render in the Breadcrumb - it gets updated during keyboard typing within the Input box
        // previousFilter is to store what we're looking at so we can easily undo/revert back when needed
        // - this only updates once currentFilter successfully changes
        const selectedValue =
            typeof filter.value === 'boolean' ? (filter.displayValue as string) : (filter.value as string);
        setCurrentFilter(selectedValue);
        setPreviousFilter(selectedValue);
    }, [filter]);

    const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        let value = event.target.value;
        const params = generateDimensionParams({
            dimensionFilters,
            resource,
            dateDimension: resource.dateDimension,
            startDate,
            endDate,
            field: filter.field,
            value,
        });
        setBreadcrumbsDimensionParams(params);
    };

    const handleOnBlur = () => {
        // switch back to the previousFilter - this is the actual filter in use on the page
        setCurrentFilter(previousFilter);
    };

    const handleOnClick = () => setExpendDimensionOptions(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // while typing in the breadcrumb to search/filter items
        setCurrentFilter(event.target.value);
        handleFetchDimensionOptions(filter.field, event.target.value);
    };

    const handleFetchDimensionOptions = useCallback(
        debounce((field, value) => {
            // call this function after a delay
            const params = generateDimensionParams({
                dimensionFilters,
                dateDimension: resource.dateDimension,
                resource,
                startDate,
                endDate,
                field,
                value,
            });
            setBreadcrumbsDimensionParams(params);
        }, 500),
        []
    );

    const handleSelect = (value: string) => {
        setCurrentFilter(value);
        setPreviousFilter(value);

        // select an Item from the filtered breadcrumbs
        handleSetFilter(filter.field, value, true);
        setExpendDimensionOptions(false);
    };

    const handleSetFilter = (field: CubedField, value: string, fuzzy: boolean) => {
        let exists = false;

        // if we are changing a filter that has children, we should reset/clear those out as they will not match anymore
        const newFilters: DimensionFilters[] = [];
        let found: boolean = false;
        let newCurrentField!: CubedField;
        dimensionFilters.every(filter => {
            if (filter.field.rawName === field.rawName) {
                // once we've found the current filter, do not add anymore
                // and set the filter value
                filter.value = value;
                filter.fuzzy = fuzzy;
                found = true;
                newFilters.push(filter);
                return true;
            }

            if (found) {
                // if we "found" on previous item, then clear the next filter and add it
                filter.value = '';
                newCurrentField = filter.field;

                // then ignore anything after
                return false;
            } else {
                // if we havent matched yet, keep adding filters
                newFilters.push(filter);
            }

            // keep going
            return true;
        });

        const currentFilters = [...dimensionFilters].map(filter => {
            if (filter.field.rawName === field.rawName) {
                filter.value = value;
                filter.fuzzy = fuzzy;
                exists = true;
            }

            return filter;
        });

        if (!exists) currentFilters.push({ field: field, resource: resource, value: value, fuzzy: fuzzy });

        setDimensionFilters([...newFilters]);

        if (newCurrentField) {
            setTableGroupBy([newCurrentField]);
        }
    };

    return (
        <BreadcrumbDimensionSearchContext.Provider
            value={{
                filter,
                startDate,
                endDate,
                dimensionFilters,
                resource,
                setDimensionFilters,
                setTableGroupBy,
                handleRemoveFilter,
                dimensionOptionsRef,
                expendDimensionOptions,
                setExpendDimensionOptions,
                dimensionOptionsDataQuery,
                dimensionOptions,
                currentFilter,
                handleOnFocus,
                handleOnBlur,
                handleOnClick,
                handleChange,
                handleSelect,
            }}
        >
            {children}
        </BreadcrumbDimensionSearchContext.Provider>
    );
};
