import React from 'react';
import styled from 'styled-components';

import { useTableContext } from '../context/table-context';

// Styles
const StyledRow = styled.tr`
    width: 100%;
    transition: 0.5s ease-in-out !important;
    position: relative;
`;

// Types
type RowTotalProps = {
    children: React.ReactNode;
};

const RowTotal = ({ children }: RowTotalProps) => {
    const { status } = useTableContext();

    if (status) {
        return <StyledRow data-testid="data-table-total-row">{children}</StyledRow>;
    }

    return null;
};

export default RowTotal;
