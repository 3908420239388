import React from 'react';
import styled from 'styled-components';

// Component
import { useTableContext } from '../../context/table-context';

// Styles
const StyledPaginationDescription = styled.div`
    font-size: 13px;
    & option,
    & select {
        font-size: 13px;
    }
`;

// Types
type DescriptionProps = {
    children: React.ReactNode;
};

const Description = ({ children }: DescriptionProps) => {
    const { status } = useTableContext();

    if (status === 'success') {
        return <StyledPaginationDescription>{children}</StyledPaginationDescription>;
    }

    return null;
};

export default Description;
