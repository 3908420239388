/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

// Highcharts
import Highcharts from 'highcharts';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers/core';

// Components
import CommonHighcharts from '../../../../components/common/common-highcharts';
import Message from '../../../../components/message';

// Types
import {
    ChartConfig,
    DataTableCellProperties,
    DataTableColumn,
    DataTableData,
    DataTableDataMeta,
    DataTableDataObject,
    DataTableSelectedRows,
    DataTableTotals,
    PieChartData,
} from '../../../types';
import { CubedField } from '../../../../types';
import { ConfigDataSuccess } from '../../../../react-query/types';

// Context
import { useGraphContext } from '../context/graph-context';

// Helpers
import { assignColour } from '../../../../helpers/colours';
import { calculateProportion, getTotals } from '../../../helpers/table-helpers';
import { sortChartData } from '../../../helpers/graph-helper';

const StyledContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 100%;
    min-height: 200px;
    height: 100%;
`;

type PieGraphProps = {
    chart: ChartConfig;
    chartYMetrics: CubedField[];
    data: DataTableDataObject[];
    dimensions: DataTableColumn[];
    metrics: DataTableColumn[];
    selectedRows: DataTableSelectedRows[];
    tableInitialAllData: DataTableData;
};

const PieGraph = ({
    chart,
    chartYMetrics,
    data,
    dimensions,
    metrics,
    selectedRows,
    tableInitialAllData,
}: PieGraphProps) => {
    const { status, isFetching, empty } = useGraphContext();

    const currencySymbol = useSelector((state: RootState) => state.user?.symbol);

    const columns = [...dimensions, ...metrics];

    const [options, setOptions] = useState<Highcharts.Options>(chart.options);
    const [isPercentage, setIsPercentage] = useState(true);

    useEffect(() => {
        if (chart) {
            getPieChartData();
        }
    }, [data, chartYMetrics, selectedRows]);

    const getPieChartData = () => {
        const pieData: PieChartData[] = [];

        if (selectedRows.length === 0) {
            columns.forEach(column => {
                if (data.length > 0) {
                    data.map(row => {
                        if (chartYMetrics[0].displayProportion) {
                            if (column.children && row[column.rawName] !== undefined) {
                                const pieValue = parseFloat(String(row[chartYMetrics[0].rawName as string].proportion));

                                if (Number.isNaN(pieValue)) {
                                    setIsPercentage(false);
                                } else {
                                    setIsPercentage(true);
                                }

                                pieData.push({
                                    name: String(row[column.rawName].value),
                                    y: pieValue,
                                    color: assignColour(row, dimensions, selectedRows)._originalInput,
                                });
                            }
                        } else {
                            setIsPercentage(false);
                        }

                        return pieData;
                    });
                }

                return pieData;
            });
        }

        if (selectedRows.length > 0) {
            const newData: ConfigDataSuccess = { meta: {} as DataTableDataMeta, objects: [] as DataTableDataObject[] };
            let totals: DataTableTotals[] = [];
            let newTotal = 0;

            selectedRows.forEach(row => {
                totals = getTotals(tableInitialAllData, dimensions, chartYMetrics);
                newTotal += Number(row.data[chartYMetrics[0].rawName].raw_value);
                totals[1].displayData = `${currencySymbol}${newTotal}`;
                row.data.colour = row.colour?.getOriginalInput() as unknown as DataTableCellProperties;
                newData.objects = [...newData.objects, row.data];
            });

            if (chartYMetrics[0].displayProportion) {
                const newProportionsData = calculateProportion(chartYMetrics[0], totals, newData);

                newProportionsData.objects.forEach(row => {
                    const pieValue = parseFloat(row[chartYMetrics[0].rawName].proportion);

                    if (Number.isNaN(pieValue)) {
                        setIsPercentage(false);
                    } else {
                        setIsPercentage(true);
                    }

                    pieData.push({
                        name: row[dimensions[0].rawName].value,
                        y: pieValue,
                        color: row.colour,
                    });
                });
            } else {
                setIsPercentage(false);
            }
        }

        const dataSorted = pieData.sort(sortChartData);

        setOptions({
            ...chart.options,
            series: [
                {
                    ...(chart.options.series ? chart.options.series[0] : {}),
                    data: dataSorted.slice(0, 10) as Highcharts.SeriesPieOptions['data'],
                } as Highcharts.SeriesPieOptions,
            ],
        });
    };

    if (!isPercentage) {
        return (
            <StyledContainer>
                <Message copy="Column not in the correct format to display on Pie Chart." type="info" size="small" />
            </StyledContainer>
        );
    }

    if (status === 'success' && !isFetching && !empty) {
        return (
            <CommonHighcharts
                highcharts={Highcharts}
                options={{ ...options }}
                containerProps={{ style: { height: '100%', width: '100%' } }}
            />
        );
    }

    return null;
};

export default PieGraph;
