import React from 'react';
import styled from 'styled-components';

const StyledDimensionsList = styled('li')`
    position: relative;
    cursor: default;
    width: inherit;
    background-color: ${props => props.theme.colours.hoverGrey};
    padding: 4px 12px;
    border-radius: 3px;
    margin: 2px;
    transition: ${props => props.theme.transition};
`;

const DimensionSearchBoxOptionsLoading = () => {
    return <StyledDimensionsList>Loading...</StyledDimensionsList>;
};

export default DimensionSearchBoxOptionsLoading;
