import React from 'react';

// Components
import CSVDownload from '../../../../components/csv-download';

// Context
import { useTableContext } from '../context/table-context';

// Types
import { AllDataExport, TableExportParam } from '../../../types';

// Types
export type TableExportProps = {
    exportParams: TableExportParam;
    totalResults: number;
    allDataExport?: AllDataExport;
};

const TableExport = ({ exportParams, totalResults, allDataExport }: TableExportProps) => {
    const { status, empty } = useTableContext();

    if (status === 'success' && exportParams && exportParams.params) {
        return (
            <CSVDownload
                noData={empty}
                params={exportParams}
                filename={exportParams.params.filter(item => item['key'] === 'filename').map(item => item.value)[0]}
                totalResults={totalResults}
                allDataExportConfig={allDataExport}
            />
        );
    }

    return null;
};

export default TableExport;
