import React from 'react';
import styled from 'styled-components';

// Styles
const StyledPlaceholder = styled.div`
    height: 11px;
    line-height: 11px;
    position: relative;
    padding-left: 24px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 4px;
    color: #0a0a0a;
    text-transform: uppercase;

    @media (max-width: 768px) {
        padding: 0;
    }
`;

type PlaceholderProps = {
    children?: React.ReactNode;
};

const Placeholder = ({ children }: PlaceholderProps) => {
    return <StyledPlaceholder>{children}</StyledPlaceholder>;
};

export default Placeholder;
