import React from 'react';
import styled from 'styled-components';

import { useTableContext } from '../../context/table-context';
import { useReportViewContext } from '../../../../context/report-view-context';

// Styles
const StyledPaginationListItem = styled.li<{ disabled: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 2px;
    background-color: ${props => (props.disabled ? props.theme.colours.offLight : props.theme.colours.borderGrey)};
    transition: 0.2s ease-in-out;
    box-sizing: border-box;
    min-width: 38px;
    border-radius: 3px;
    cursor: ${props => (props.disabled ? 'not-allowed !important' : 'pointer')};
    border-color: ${props => props.disabled && props.theme.colours.borderGrey};
    color: ${props => props.disabled && props.theme.colours.borderGrey};

    &:hover {
        background-color: ${props =>
            !props.disabled ? (props.disabled ? props.theme.colours.borderGrey : props.theme.colours.midGrey) : ''};
    }

    &:active {
        background-color: ${props => !props.disabled && props.theme.colours.primaryLight};
    }
`;

// Types
type IncreaseItemProps = {
    increment: number;
    onClick: () => void;
    children: React.ReactNode;
};

const IncreaseItem = ({ increment, onClick, children }: IncreaseItemProps) => {
    const { status } = useTableContext();
    const { tablePagination } = useReportViewContext();

    const minPage = 1;
    const maxPage = Math.ceil(tablePagination.totalResults / tablePagination.rowDefault);
    const newPage = tablePagination.currentPage + increment;
    let disabled = true;

    if (increment === 1) {
        disabled = newPage > maxPage;
    } else {
        disabled = newPage < minPage;
    }

    if (status === 'success') {
        return (
            <StyledPaginationListItem onClick={onClick} disabled={disabled}>
                {children}
            </StyledPaginationListItem>
        );
    }

    return null;
};

export default IncreaseItem;
