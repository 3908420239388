import React, { createContext, useContext } from 'react';
import { QueryStatus } from '@tanstack/react-query';

export type TableContextValues = {
    status: QueryStatus;
    isFetching: boolean;
    tableDisabled: boolean;
    empty: boolean;
};

const TableContext = createContext<TableContextValues | undefined>(undefined);

export const useTableContext = () => {
    const context = useContext(TableContext);
    if (context === undefined) {
        throw new Error('"Table" child components cannot be rendered outside of the Table component');
    }

    return context;
};

type TableProviderProps = {
    status: QueryStatus;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
    children: React.ReactNode;
};

export const TableProvider = ({ status, isFetching, disabled, empty, children }: TableProviderProps) => {
    return (
        <TableContext.Provider
            value={{
                status,
                isFetching,
                tableDisabled: disabled,
                empty,
            }}
        >
            {children}
        </TableContext.Provider>
    );
};
