import { keepPreviousData, useQuery } from '@tanstack/react-query';
import fetchResourceReportView from '../query-functions/fetch-resource-report-view';

// Types
import { CubedConfigResource, CubedResource } from '../../types';
import { ResourceDataSuccess } from '../types';

// helpers
import generateQueryKey from '../helpers/generate-query-key';

export type ResourceParams = {
    key: string;
    value: string | number | boolean;
};

export type UseFetchResourceReportViewArgs<TResponse, TSelectResponse> = {
    resource?: CubedResource | CubedConfigResource;
    params?: ResourceParams[];
    staleTime?: number;
    enabled?: boolean;
    isPaginated?: boolean;
    select?: (data: TResponse, isLoading?: boolean) => TSelectResponse;
    refetchInterval?: number | false;
};

const useFetchResourceReportView = <TResponse = ResourceDataSuccess, TSelectResponse = TResponse>({
    resource,
    params = [],
    staleTime = 1000 * 60 * 10, // 10 minutes
    enabled = !!resource,
    isPaginated = false,
    refetchInterval = false,
    select,
}: UseFetchResourceReportViewArgs<TResponse, TSelectResponse>) => {
    if (typeof enabled === 'boolean') {
        enabled = enabled && !!resource;
    }

    const response = useQuery({
        queryKey: generateQueryKey({ resource, params }),
        queryFn: fetchResourceReportView,
        placeholderData: isPaginated ? keepPreviousData : undefined,
        staleTime,
        enabled,
        select,
        refetchInterval,
    });

    return response;
};

export default useFetchResourceReportView;
