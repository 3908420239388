import React from 'react';
import { Moment } from 'moment';

import ReportViewLayoutWrapper from '../report-view/report-view-layout';
import { config } from '../configurations/error-reporting';
import { ReportConfig } from '../report-view/types';

type ReportValidationMessagesProps = {
    startDate: Moment;
    endDate: Moment;
    selectedProducts: [];
    selectedMetrics: [];
};

const ReportValidationMessages = (props: ReportValidationMessagesProps) => {
    const { startDate, endDate, selectedProducts, selectedMetrics } = props;

    return (
        <ReportViewLayoutWrapper
            config={config as unknown as ReportConfig}
            startDate={startDate}
            endDate={endDate}
            selectedProducts={selectedProducts}
            selectedMetrics={selectedMetrics}
        />
    );
};

export default ReportValidationMessages;
