import React from 'react';
import styled from 'styled-components';

// Redux
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/reducers/core';

// Helper
import { formatSalesTitle } from '../../../../helpers/formatGlobalSaleTitle';
import { CubedField } from '../../../../types';

// Styles
const StyledDropdownListItem = styled.option`
    background-color: ${props => props.theme.colours.white};
`;

type DropdownListItemsProps = {
    option: CubedField;
};

const DropdownListItems = ({ option }: DropdownListItemsProps) => {
    const { saleTitle } = useSelector((state: RootState) => state);

    return (
        <StyledDropdownListItem value={option.rawName} key={option.rawName}>
            {formatSalesTitle(option.displayName, saleTitle.globalSalesTitle)}
        </StyledDropdownListItem>
    );
};

export default DropdownListItems;
