import React from 'react';
import styled from 'styled-components';

import IconHome from '../../../../components/icons/home';

// Styles
const StyledContainer = styled.div`
    z-index: 2;
`;

const StyledPlaceholder = styled.div`
    height: 11px;
    line-height: 11px;
    position: relative;
    padding-left: 24px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 2px;
    padding-top: 2px;
    text-transform: uppercase;
    color: #0a0a0a;
`;

const StyledContentWrapper = styled.div`
    display: block;
    height: 100%;
    width: inherit;
    background-color: ${props => props.theme.colours.white};
    padding: 0 6px 0 0;
    cursor: pointer;
    border-radius: 0 37px 37px 0;
`;

const StyledContentHome = styled.div`
    line-height: 37px;
    height: 37px;
    min-width: 54px;
    width: 54px;
    padding: 0.5rem;
    border: none;
    border-top: 1px solid ${props => props.theme.colours.lightGrey};
    border-bottom: 1px solid #d2d2d2;
    border-radius: 0 37px 37px 0;
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};
    display: flex;
    align-items: center;
    background-color: ${props => props.theme.colours.lightGrey};
    &:hover {
        background-color: ${props => props.theme.colours.hoverGrey};
    }

    & svg {
        fill: ${props => props.theme.colours.black};
        height: 27px;
        width: 27px;
    }
`;

type HomeProps = {
    onClick: () => void;
};

const Home = ({ onClick }: HomeProps) => {
    return (
        <StyledContainer>
            <StyledPlaceholder />
            <StyledContentWrapper>
                <StyledContentHome onClick={onClick}>
                    <IconHome />
                </StyledContentHome>
            </StyledContentWrapper>
        </StyledContainer>
    );
};

export default Home;
