import React, { Component } from 'react';
import { Moment } from 'moment';

import ReportViewLayoutWrapper from '../report-view/report-view-layout';
import { config } from '../configurations/seo-keyword-marketshare';
import { ReportConfig } from '../report-view/types';

interface ReportSeogdKeywordVisibilityProps {
    startDate: Moment;
    endDate: Moment;
    selectedProducts: [];
    selectedMetrics: [];
}

class ReportSeogdKeywordVisibility extends Component<ReportSeogdKeywordVisibilityProps> {
    render() {
        return (
            <ReportViewLayoutWrapper
                config={config as unknown as ReportConfig}
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                selectedProducts={this.props.selectedProducts}
                selectedMetrics={this.props.selectedMetrics}
            />
        );
    }
}

export default ReportSeogdKeywordVisibility;
