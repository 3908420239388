import React from 'react';
import styled from 'styled-components';

// Context
import { useBreadcrumbContext } from '../context/breadcrumb-context';

// Styles
const StyledBreadcrumbsItemDropdown = styled.div<{
    expendFieldOptions: boolean;
}>`
    ${props => props.theme.boxShadow(1)}
    position: absolute;
    background-color: ${props => props.theme.colours.textLight};
    transition: ${props => props.theme.transition}, visibility 0s linear 0.3s;
    z-index: 2;
    width: 100%;
    padding: 6px;
    overflow: hidden;

    visibility: ${props => (props.expendFieldOptions ? 'visible' : 'hidden')};
    max-height: ${props => (props.expendFieldOptions ? '600px' : '0')};

    right: -212px;
    top: 31px;
    min-width: 240px;
    overflow-y: auto;
`;

type DimensionSelectBoxOptionsProps = {
    children: React.ReactNode;
};

const DimensionSelectBoxOptions = ({ children }: DimensionSelectBoxOptionsProps) => {
    const { expendFieldOptions } = useBreadcrumbContext();

    return (
        <StyledBreadcrumbsItemDropdown expendFieldOptions={expendFieldOptions}>
            <ul>{children}</ul>
        </StyledBreadcrumbsItemDropdown>
    );
};

export default DimensionSelectBoxOptions;
