import React, { memo } from 'react';
import styled, { css } from 'styled-components';
import { isEqual } from 'lodash';

// Redux
import { useAppSelector } from '../../../../../redux/hooks';

// Context
import { useTableContext } from '../../context/table-context';
import { useReportViewContext } from '../../../../context/report-view-context';

// Image
import arrowImage from '../../../../../assets/images/icons/icon-arrowup-black.svg';

// Component
import Tooltip from '../../../../../components/tooltip';

// Helper
import { formatSalesTitle } from '../../../../../helpers/formatGlobalSaleTitle';

// Type
import { DataTableColumn } from '../../../../types';
import { QueryStatus } from '@tanstack/react-query';

// Styles
const StyledStickyColumn = css`
    position: sticky !important;
    left: 50px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.16);
    z-index: 2;
`;

const StyledStickyCheckBoxColumn = css`
    position: sticky !important;
    left: 0px;
    z-index: 2;
`;

const StyledTableHeader = styled.th<{
    isDimension: boolean;
    id: string;
    scrollPosX: number;
    columnWidth: string | number;
    status: QueryStatus;
    disabled: boolean;
}>`
    ${props => props.isDimension && props.scrollPosX > 0 && StyledStickyColumn};
    ${props => props.id === 'checkbox' && props.scrollPosX > 0 && StyledStickyCheckBoxColumn};

    line-height: 17px;
    font-size: 13px;
    color: ${props =>
        props.status === 'pending' || props.status === 'error' || props.disabled
            ? props.theme.colours.borderGrey
            : props.theme.colours.textDefault};
    font-weight: 500;
    ${props => props.theme.no_select()};
    padding: 8px 10px !important;
    cursor: ${props => (props.id === 'checkbox' ? 'default' : 'pointer')};
    border: 1px solid ${props => props.theme.colours.borderGrey};
    transition: 0.2s ease-in-out !important;
    background-color: ${props => props.theme.colours.white};
    background-clip: padding-box;
    position: relative;

    max-width: ${props => props.columnWidth};
    min-width: ${props => props.columnWidth};
    width: ${props => props.isDimension && props.columnWidth};
`;

const StyledTableHeaderIcon = styled.i<{
    isVisible: boolean;
    orderDir: string;
}>`
    transition: 0.2s ease-in-out;
    position: absolute;
    left: 10px;
    top: calc(50% - 6px);
    transform: rotate(${props => (props.orderDir === 'asc' ? `0deg` : `180deg`)});
    display: inline;
    vertical-align: middle;
    opacity: ${props => (props.isVisible ? 0.5 : 0)};
    height: 12px;
    width: 12px;
    background-image: url(${arrowImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

const StyledTableHeaderText = styled.div`
    display: inline;
    padding: 3px;
    font-size: 13px;
`;

type CellHeaderProps = {
    column: DataTableColumn;
    onClick: () => void;
    children?: React.ReactNode;
};

const CellHeader = ({ column, onClick, children }: CellHeaderProps) => {
    const { globalSalesTitle } = useAppSelector(state => state.saleTitle);
    const { status, tableDisabled } = useTableContext();
    const { tableOrderBy, tableOrderDir, scrollPosX } = useReportViewContext();

    const { id, isDimension, fixedWidth, metricWidth } = column;
    const columnWidth = isDimension
        ? fixedWidth !== undefined
            ? fixedWidth
            : '270px'
        : metricWidth !== undefined
        ? metricWidth
        : '12em';

    if (status) {
        return (
            <StyledTableHeader
                isDimension={isDimension!}
                id={id}
                scrollPosX={scrollPosX}
                columnWidth={columnWidth}
                status={status}
                disabled={tableDisabled}
                onClick={onClick}
            >
                {column.description && <Tooltip copy={column.description} />}
                <StyledTableHeaderIcon isVisible={tableOrderBy === column} orderDir={tableOrderDir} />
                <StyledTableHeaderText data-testid="table-header-text">
                    {formatSalesTitle(column.displayName, globalSalesTitle)}
                </StyledTableHeaderText>
                {children}
            </StyledTableHeader>
        );
    }

    return null;
};

export default memo(CellHeader, isEqual);
