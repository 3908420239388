import React from 'react';
import styled from 'styled-components';

// Styles
const StyledDropdownTitle = styled.div`
    font-weight: 700;
    margin: 15px 0px 0px 12px;
    letter-spacing: 0.04rem;
`;

type DropdownTitleProps = {
    children: React.ReactNode;
};

const DropdownTitle = ({ children }: DropdownTitleProps) => {
    return <StyledDropdownTitle>{children}</StyledDropdownTitle>;
};

export default DropdownTitle;
