import React from 'react';
import styled from 'styled-components';

// Styles
const StyledDropdownList = styled.select<{ showDropdownLegend?: boolean }>`
    line-height: 40px;
    width: ${props => (props.showDropdownLegend ? `80%` : `100%`)};
    float: ${props => props.showDropdownLegend && `right`};
    margin: 12px 0px;
    font-size: 16px;
    background-color: transparent;
    color: ${props => props.theme.colours.textDefault};
    border: 0;
    border-bottom: 1px solid ${props => props.theme.colours.borderGrey};
`;

type DropdownListProps = {
    value: string;
    showDropdownLegend?: boolean;
    onSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    children: React.ReactNode;
};

const DropdownList = ({ value, showDropdownLegend, onSelectChange, children }: DropdownListProps) => {
    return (
        <StyledDropdownList onChange={onSelectChange} value={value} showDropdownLegend={showDropdownLegend}>
            {children}
        </StyledDropdownList>
    );
};

export default DropdownList;
