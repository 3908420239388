import React from 'react';
import styled from 'styled-components';

const StyledTitle = styled.div`
    padding: 10px 20px;
    font-weight: 500;
`;

export type FilterTitleProps = {
    children: React.ReactNode;
};

const FilterTitle = ({ children }: FilterTitleProps) => {
    return <StyledTitle>{children}</StyledTitle>;
};

export default FilterTitle;
