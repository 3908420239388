import React from 'react';
import styled, { css } from 'styled-components';

import { DataTableColumn } from '../../../../types';
import { useReportViewContext } from '../../../../context/report-view-context';
import { useTableContext } from '../../context/table-context';

// Styles
const StyledStickyColumn = css`
    position: sticky !important;
    left: 50px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    box-shadow: 3px 0 4px rgba(0, 0, 0, 0.16);
    z-index: 2;
`;

const StyledStickyCheckBoxColumn = css`
    position: sticky !important;
    left: 0px;
    z-index: 2;
`;

const StyledTableTotalsCell = styled.th<{
    isDimension: boolean;
    id: string;
    scrollPosX: number;
    width: string | number;
}>`
    ${props => props.isDimension && props.scrollPosX > 0 && StyledStickyColumn};
    ${props => props.id === 'checkbox' && props.scrollPosX > 0 && StyledStickyCheckBoxColumn};

    ${props => props.theme.no_select()};
    line-height: 17px;
    font-size: 13px;
    text-align: right;
    padding: 3px;
    border: 1px solid ${props => props.theme.colours.borderGrey};
    transition: 0.2s ease-in-out !important;
    background-color: ${props => props.theme.colours.white};
    background-clip: padding-box;
    position: relative;

    max-width: ${props => props.width};
    min-width: ${props => props.width};
    width: ${props => props.isDimension && props.width};
`;

// Types
type CellTotalProps = {
    id: string;
    isDimension?: boolean;
    column: DataTableColumn;
    children: React.ReactNode;
};

const CellTotal = ({ id, isDimension, column, children }: CellTotalProps) => {
    const { scrollPosX } = useReportViewContext();
    const { status, empty } = useTableContext();

    const columnWidth = isDimension ? column?.fixedWidth || '270px' : '';

    if (status === 'success' && !empty) {
        return (
            <StyledTableTotalsCell
                key="1"
                isDimension={column?.isDimension!}
                id={id}
                scrollPosX={scrollPosX}
                width={columnWidth}
            >
                {children}
            </StyledTableTotalsCell>
        );
    }

    return null;
};

export default CellTotal;
