import axios, { AxiosError } from 'axios';

import { generateUrl } from '../../helpers/request-builder';
import { mapObjectReportView } from '../../section-dashboard/helpers/map-object';

export const fetchResourceReportView = async ({ queryKey }: { queryKey: any }) => {
    const { category, id, params } = queryKey[0];

    const url = generateUrl(category, id, params);

    return axios({
        method: 'GET',
        url: url,
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json',
        },
    })
        .then(response => {
            if (category === 'report-view') {
                return response.data;
            }

            return {
                ...response.data,
                objects: mapObjectReportView(response.data.objects),
            };
        })
        .catch((error: AxiosError) => {
            throw new Error(error.message);
        });
};

export default fetchResourceReportView;
