import React, { createContext } from 'react';

import { DataTableSelectedRows } from '../../../types';

type SelectionLegendContextProps = {
    emptyItemCount: number;
};

const SelectionLegendContext = createContext<SelectionLegendContextProps | undefined>(undefined);

type SelectionLegendProviderProps = {
    selectedRows: DataTableSelectedRows[];
    maxLength?: number;
    children: React.ReactNode;
};

export const SelectionLegendProvider = ({ selectedRows, maxLength = 5, children }: SelectionLegendProviderProps) => {
    const emptyItemCount = selectedRows.length < 1 ? maxLength - 1 : maxLength - selectedRows.length;

    return <SelectionLegendContext.Provider value={{ emptyItemCount }}>{children}</SelectionLegendContext.Provider>;
};

export const useSelectionLegend = () => {
    const context = React.useContext(SelectionLegendContext);
    if (!context) {
        throw new Error('useSelectionLegend must be used within a SelectionLegendProvider');
    }

    return context;
};
