import React from 'react';

import DataTable from './table';
import { DataTableCellProperties, DataTableColumn, DataTableDataObject } from '../../types';

type GenericTableRowProps = {
    data: DataTableDataObject;
    columns: DataTableColumn[];
    handleCheckboxChange: (rowId: DataTableDataObject) => void;
    handleDimensionClick: (
        event: React.MouseEvent<HTMLElement>,
        column: DataTableColumn,
        properties: DataTableCellProperties
    ) => void;
};

const GenericTableRow = ({ data, columns, handleCheckboxChange, handleDimensionClick }: GenericTableRowProps) => {
    return (
        <DataTable.Row key={data.__id} selected={data.selected}>
            {columns.map(column => {
                if (column.id === 'checkbox') {
                    return (
                        <DataTable.CellCheckbox
                            key={`checkbox-${column.id}-${data.__id}`}
                            rowId={data.id as unknown as string}
                            checked={data.selected ? true : false}
                            onCheckedChange={() => handleCheckboxChange(data)}
                        />
                    );
                }

                return (
                    <DataTable.CellBody
                        key={`cell-${column.id}-${data.__id}`}
                        rowId={data.__id}
                        column={column}
                        properties={data[column.rawName]}
                        handleDimensionClick={handleDimensionClick}
                    />
                );
            })}
        </DataTable.Row>
    );
};

export default GenericTableRow;
