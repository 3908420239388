import React from 'react';
import styled from 'styled-components';

// Context
import { useGraphContext } from '../context/graph-context';

// Components
import Message from '../../../../components/message';

// Widgets
import WidgetBaseLoader from '../../../../section-dashboard/widgets/base/widget-base-loader';
import dataTypes from '../../../../filter-bar/enums/data-types';

const StyledChartContainer = styled.div`
    overflow: auto;
`;

const StyledStatusContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    width: 100%;
    min-height: 200px;
`;

type GraphWrapperProps = { children: React.ReactNode };

const GraphWrapper = ({ children }: GraphWrapperProps) => {
    const { status, isFetching, empty, startDate, endDate, chartXMetric, selectedChartType } = useGraphContext();

    if (isFetching || status === 'pending') {
        return (
            <StyledStatusContainer>
                <WidgetBaseLoader width={40} height={40} margin={0} />
            </StyledStatusContainer>
        );
    }

    if (
        (selectedChartType === 'line' || selectedChartType === 'areaSpline' || selectedChartType === 'trendLine') &&
        chartXMetric.dataType === dataTypes.DATE &&
        startDate === endDate
    ) {
        return (
            <StyledStatusContainer>
                <Message
                    title="Not enough data."
                    copy="Singular day selected. Please select a date range if you want to view a line chart."
                    type="info"
                    size="small"
                />
            </StyledStatusContainer>
        );
    }

    if (empty) {
        return (
            <StyledStatusContainer>
                <Message copy="No Data Available." type="info" size="small" />
            </StyledStatusContainer>
        );
    }

    if (status === 'error') {
        return (
            <StyledStatusContainer>
                <Message
                    type="error"
                    size="small"
                    copy="There was an error retrieving your data. Please try again later or contact support@cubed.email"
                />
            </StyledStatusContainer>
        );
    }

    return <StyledChartContainer>{children}</StyledChartContainer>;
};

export default GraphWrapper;
