import React from 'react';
import styled from 'styled-components';

//Types
import { useSelectionLegend } from '../context/selection-legend-context';

const StyledEmptyList = styled.li`
    background-color: ${props => props.theme.colours.offLight};
    padding: 3px;
    margin-bottom: 12px;
    border-radius: 12px;
    padding: 23px;
`;

const LegendEmpty = () => {
    const { emptyItemCount } = useSelectionLegend();

    return (
        <>
            {Array.from({ length: emptyItemCount }, (_, index) => (
                <StyledEmptyList key={index} />
            ))}
        </>
    );
};

export default LegendEmpty;
