import React from 'react';
import styled from 'styled-components';

const StyledCharTypeSelectorWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`;

type TabListProps = { children: React.ReactNode };

const TabList = ({ children }: TabListProps) => {
    return <StyledCharTypeSelectorWrapper>{children}</StyledCharTypeSelectorWrapper>;
};

export default TabList;
