import React from 'react';
import styled from 'styled-components';

// Image
import DropDownArrowImage from '../../../../assets/images/icons/drop-down-arrow.svg';

// Context
import { useBreadcrumbContext } from '../context/breadcrumb-context';

// Styles
const StyledDropdownButton = styled.span`
    cursor: pointer;
    padding: 12px;
    background-color: ${props => props.theme.colours.offBlackLighter};
    transition: ${props => props.theme.transition};

    &:hover {
        background-color: ${props => props.theme.colours.black};
    }
`;

const StyledDropdownButtonIcon = styled.div<{
    backgroundImage: string;
}>`
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url(${props => props.backgroundImage});
    background-size: contain;
    background-repeat: no-repeat;
`;

const DimensionSelectBoxContentDropdownIcon = () => {
    const { fieldOptionsRef, expendFieldOptions, setExpendFieldOptions } = useBreadcrumbContext();

    const handleToggleDropdownList = () => {
        setExpendFieldOptions(!expendFieldOptions);
    };

    return (
        <StyledDropdownButton ref={fieldOptionsRef} onClick={handleToggleDropdownList}>
            <StyledDropdownButtonIcon backgroundImage={DropDownArrowImage} />
        </StyledDropdownButton>
    );
};

export default DimensionSelectBoxContentDropdownIcon;
