import React from 'react';
import styled from 'styled-components';

const StyledSelectionLegendDiv = styled.div`
    display: inline-block;
    width: 100%;
    position: relative;
`;

export type LegendProps = {
    children: React.ReactNode;
};

const Legend = ({ children }: LegendProps) => {
    return <StyledSelectionLegendDiv>{children}</StyledSelectionLegendDiv>;
};

export default Legend;
