import React from 'react';
import styled from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import IconEllipsis from '../../../../../components/icons/ellipsis';

const StyledChartMenuButton = styled(DropdownMenu.Trigger)`
    width: 30px;
    height: 30px;
    border: none;
    outline: none;
    cursor: ${props => !props.disabled && 'pointer'}!important;
    margin-left: 0 auto;
    border-radius: 9px;
    padding: 1px;
    margin: 0.5px;
    background-color: ${props => props.theme.colours.white};
    &:hover {
        background-color: ${props => !props.disabled && props.theme.colours.selectionGrey};
    }
`;

const StyledImage = styled(IconEllipsis)<{ disabled: boolean }>`
    width: 20px;
    height: 20px;
    position: relative;
    top: 2px;
    color: ${props => (props.disabled ? props.theme.colours.darkGrey : props.theme.colours.offBlackLighter)};
`;

const StyledContent = styled(DropdownMenu.Content)`
    background-color: ${props => props.theme.colours.white};
    min-width: 160px;
    padding: 10px 0px;
    border-radius: 6px;
    box-shadow: 0px 1px 25px -1px rgba(0, 0, 0, 0.1), 0px 1px 12px rgba(0, 0, 0, 0.1);
    font-size: 0.9rem;
`;

export type FilterWrapperProps = {
    disabled?: boolean;
    children: React.ReactNode;
};

const FilterWrapper = ({ disabled, children }: FilterWrapperProps) => {
    return (
        <DropdownMenu.Root>
            <StyledChartMenuButton disabled={disabled} data-testid={'chart-menu'}>
                <StyledImage disabled={disabled!} />
            </StyledChartMenuButton>

            <DropdownMenu.Portal>
                <StyledContent align={'end'}>{children}</StyledContent>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
};

export default FilterWrapper;
