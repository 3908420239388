import React from 'react';
import styled, { css } from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

// Components
import IconCircle from '../../../../../components/icons/circle';
import IconCircleDot from '../../../../../components/icons/circle-dot';

// Types
import { MenuRadioOption } from '../../../../types';

const StyledItemIndicator = styled(DropdownMenu.ItemIndicator)`
    width: 18px;
    height: 18px;
`;

const BaseIconStyles = css`
    width: 18px;
    height: auto;
    border-radius: 50%;
`;

const StyledIconCircle = styled(IconCircle)<{ disabled: boolean }>`
    ${BaseIconStyles}
    color: ${props =>
        props.disabled ? props.theme.colours.midGrey : props.theme.sectionDashboard.widget.menu.textColor};
    background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundHover};
`;

const StyledIconCircleDot = styled(IconCircleDot)<{ disabled: boolean }>`
    ${BaseIconStyles}
    color: ${props =>
        props.disabled ? props.theme.colours.midGrey : props.theme.sectionDashboard.widget.menu.textColor};
    background-color: ${props => props.theme.colours.white};
`;

const StyledLabel = styled.div<{ disabled: boolean }>`
    position: relative;
    top: 2px;
    font-weight: 500;
    color: ${props =>
        props.disabled ? props.theme.colours.midGrey : props.theme.sectionDashboard.widget.menu.textColor};
`;

const StyledRadioItem = styled(DropdownMenu.RadioItem)<{ disabled: boolean }>`
    display: flex;
    gap: 10px;
    padding: 5px 20px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    outline: none;

    &:hover,
    &[data-highlighted] {
        background-color: ${props => !props.disabled && props.theme.sectionDashboard.widget.menu.backgroundHover};
    }

    &:hover,
    :focus ${StyledIconCircleDot} {
        color: ${props => props.theme.colours.black};
        fill: ${props => props.theme.colours.black};
    }

    &:hover,
    :focus ${StyledLabel} {
        color: ${props => props.theme.colours.black};
    }

    &[data-state='checked'] ${StyledIconCircle} {
        display: none;
    }
`;

export type FilterRadioProps = {
    option: MenuRadioOption;
    disabled?: boolean;
};

const FilterRadio = ({ option, disabled }: FilterRadioProps) => {
    const handleSelect = (event: Event): void => {
        event.preventDefault();
    };

    return (
        <StyledRadioItem value={option.value} disabled={disabled!} onSelect={handleSelect} key={option.value}>
            <StyledIconCircle disabled={disabled!} />
            <StyledItemIndicator>
                <StyledIconCircleDot disabled={disabled!} />
            </StyledItemIndicator>
            <StyledLabel disabled={disabled!}>{option.label}</StyledLabel>
        </StyledRadioItem>
    );
};

export default FilterRadio;
