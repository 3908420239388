import React from 'react';
import styled from 'styled-components';

import { useTableContext } from '../../context/table-context';

// Styles
const StyledTabSkeletonWrapper = styled.div`
    width: 50%;
    display: inline-block;
    margin-right: auto;
`;

const StyledTabSkeleton = styled.div`
    height: 32px;
    position: relative;
`;

const StyledTabSkeletonItem = styled.div`
    display: inline-block;
    position: relative;
    height: 100%;
    width: calc(25% - 12px);
    margin: 0 6px;
    border-radius: 12px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledTabListWrapper = styled.div`
    margin-right: auto;
`;

// Types
export type TabsWrapperProps = {
    children: React.ReactNode;
};

const TabsWrapper = ({ children }: TabsWrapperProps) => {
    const { status } = useTableContext();

    if (status === 'success') {
        return <StyledTabListWrapper>{children}</StyledTabListWrapper>;
    }

    return (
        <StyledTabSkeletonWrapper>
            <StyledTabSkeleton>
                <StyledTabSkeletonItem />
                <StyledTabSkeletonItem />
                <StyledTabSkeletonItem />
                <StyledTabSkeletonItem />
            </StyledTabSkeleton>
        </StyledTabSkeletonWrapper>
    );
};

export default TabsWrapper;
