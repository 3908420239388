import React from 'react';
import styled from 'styled-components';

// Context
import { useTableContext } from '../../context/table-context';

// Styles
const StyledPaginationsSkeletonWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: end;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 1rem;
`;

const StyledPaginationDescriptionSkeletonItem = styled.div`
    display: inline-block;
    position: relative;
    height: 20px;
    width: 20%;
    border-radius: 10px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledPaginationItemWrapperSkeleton = styled.div`
    position: relative;
    display: flex;
    gap: 0.2rem;
    width: 10%;
`;

const StyledPaginationItemSkeleton = styled.div`
    display: inline-block;
    position: relative;
    height: 35px;
    width: 100%;
    border-radius: 10px;
    ${props => props.theme.skeletonLoaderGradient};
`;

const StyledPagination = styled.div`
    margin-top: 1rem;
    user-select: none;
    text-align: right;
`;

// Types
export type PaginationProps = {
    children: React.ReactNode;
};

const Pagination = ({ children }: PaginationProps) => {
    const { status, empty } = useTableContext();

    if (status === 'pending') {
        return (
            <StyledPaginationsSkeletonWrapper>
                <StyledPaginationDescriptionSkeletonItem />
                <StyledPaginationItemWrapperSkeleton>
                    <StyledPaginationItemSkeleton />
                    <StyledPaginationItemSkeleton />
                    <StyledPaginationItemSkeleton />
                </StyledPaginationItemWrapperSkeleton>
            </StyledPaginationsSkeletonWrapper>
        );
    }

    if (status === 'error' || empty) {
        return null;
    }

    return <StyledPagination>{children}</StyledPagination>;
};

export default Pagination;
