import React from 'react';
import styled from 'styled-components';
import { DimensionOptions } from '../../../../breadcrumbs/types';
import { useBreadcrumbDimensionSearchContext } from '../context/breadcrumb-dimension-search-context';

const StyledDimensionsList = styled('li')<{ isLoading?: boolean; isEmpty?: boolean }>`
    position: relative;
    cursor: ${props => (props.isLoading || props.isEmpty ? 'default' : 'pointer')};
    width: inherit;
    background-color: ${props => props.theme.colours.hoverGrey};
    padding: 4px 12px;
    border-radius: 3px;
    margin: 2px;
    transition: ${props => props.theme.transition};

    &:hover {
        background-color: ${props => (props.isLoading || props.isEmpty ? props.theme.colours.hoverGrey : '#dfdfdf')};
    }
`;

const DimensionSearchBoxOptionsList = () => {
    const { dimensionOptions, filter, handleSelect } = useBreadcrumbDimensionSearchContext();

    return (
        <>
            {dimensionOptions.map((option: DimensionOptions) => {
                if (option[filter.field.rawName]) {
                    return (
                        <StyledDimensionsList
                            key={option.id as unknown as string}
                            onClick={() => handleSelect(option[filter.field.rawName].value)}
                        >
                            <span>{option[filter.field.rawName].value}</span>
                        </StyledDimensionsList>
                    );
                }

                return null;
            })}
        </>
    );
};

export default DimensionSearchBoxOptionsList;
