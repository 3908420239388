import React, { useState } from 'react';
import styled from 'styled-components';
import { useReportViewContext } from '../../../../context/report-view-context';

// Icons
import IconPieChart from '../../../../../components/icons/pie-chart';
import IconLineChart from '../../../../../components/icons/line-chart';
import IconBarColumnChart from '../../../../../components/icons/bar-column-chart';
import IconBarHorizontalChart from '../../../../../components/icons/bar-horizontal-chart';
import IconDoughnutChart from '../../../../../components/icons/doughnut-chart';
import IconTrendLineChart from '../../../../../components/icons/trend-line-chart';

// Components
import ToolTip from './tooltip';

// Types
import { ChartType } from '../../../../types';

// Chart icon styles
const svgImageStyles = `
    width: 20px;
    height: 20px;
    position: relative;
    top: 2px;
    transition: transform 0.3s ease;
`;

const StyledIcon = styled.div`
    ${svgImageStyles}
    fill: ${props => props.theme.colours.black};

    &:hover {
        transform: scale(1.1);
    }
`;

const StyledImageButton = styled.button<{ chartType: string; selectedChartType: string }>`
    width: 30px;
    height: 30px;
    border: none;
    cursor: pointer;
    background-color: ${({ chartType, selectedChartType, theme }) =>
        chartType === selectedChartType ? theme.colours.selectionGrey : theme.colours.white};
    margin: 0.5px;
    border-radius: 9px;
    padding: 1px;
    transition: background-color 0.3s ease, transform 0.2s ease;

    :hover {
        background-color: ${({ theme }) => theme.colours.selectionGrey};
        transform: translateY(-2px);
    }
`;

const chartImageComponents = {
    line: IconLineChart,
    areaSpline: IconLineChart,
    pie: IconPieChart,
    bar: IconBarColumnChart,
    barHorizontal: IconBarHorizontalChart,
    donut: IconDoughnutChart,
    trendLine: IconTrendLineChart,
};

const chartTypeLabels: Record<ChartType, string> = {
    line: 'Line',
    areaSpline: 'Area Spline',
    trendLine: 'Trendline',
    donut: 'Doughnut',
    bar: 'Bar',
    barHorizontal: 'Bar Horizontal',
    pie: 'Pie',
};

type TabListItemProps = { chartType: ChartType };

const TabListItem = ({ chartType }: TabListItemProps) => {
    const { selectedChartType, handleChartTypeSelection } = useReportViewContext();

    const [hover, setHover] = useState(false);

    const ChartIcon = chartImageComponents[chartType];

    return (
        <>
            <div onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <StyledImageButton
                    onClick={() => handleChartTypeSelection(chartType)}
                    selectedChartType={selectedChartType}
                    chartType={chartType}
                    data-testid={chartType + ' chart'}
                >
                    <StyledIcon as={ChartIcon} />
                </StyledImageButton>
            </div>

            {hover && <ToolTip label={chartTypeLabels[chartType]} />}
        </>
    );
};

export default TabListItem;
