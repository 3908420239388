import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import IconSearch from '../../../../components/icons/search';
import IconCancel from '../../../../components/icons/cancel';

const StyledContainer = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: space-between;
    background-color: ${props => props.theme.colours.offLight};
    border: 2px solid ${props => (props.selected ? props.theme.colours.midGrey : props.theme.colours.borderGrey)};
    border-radius: 3px;
    ${props => props.theme.transition};
    width: 100%;
`;

const StyledInput = styled.input`
    font-family: inherit;
    border: none;
    background-color: ${props => props.theme.colours.offLight};
    padding: 7px;
    font-size: 13px;
`;

const StyledIconBaseStyles = css`
    height: 18px;
    cursor: pointer;
    float: right;
`;

const StyledIconSearch = styled(IconSearch)`
    ${StyledIconBaseStyles}
    color: ${props => props.theme.colours.midGrey};
    margin: 6px;
`;

const StyledIconCancel = styled(IconCancel)`
    ${StyledIconBaseStyles}
    color: ${props => props.theme.colours.red};
    fill-opacity: 0.5;
    margin: 6px 6px 2px 0px;
    &:hover {
        fill-opacity: 0.8;
    }
`;

export type TableSearchProps = {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
};

const TableSearch = ({ value, onChange, onClear }: TableSearchProps) => {
    const [selected, setSelected] = useState(false);

    const onInputFocus = () => {
        setSelected(true);
    };

    const onInputBlur = () => {
        setSelected(false);
    };

    return (
        <StyledContainer onFocus={onInputFocus} onBlur={onInputBlur} selected={selected}>
            <StyledInput type="text" onChange={onChange} value={value} placeholder="Search..." />
            {value !== '' ? (
                <div onClick={onClear}>
                    <StyledIconCancel />
                </div>
            ) : (
                <StyledIconSearch />
            )}
        </StyledContainer>
    );
};

export default TableSearch;
