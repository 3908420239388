import moment from 'moment';
import {
    AnnotationConfig,
    AnnotationResponseItem,
    ChartSeriesData,
    ExtendedAnnotationConfigItem,
    SeriesGraphData,
} from '../types';

export type FormatAnnotationResponseData = {
    name: string;
    data: SeriesGraphData;
};

export const formatAnnotationResponse = (
    chartData: ChartSeriesData[],
    annotationData: AnnotationResponseItem
): FormatAnnotationResponseData[] => {
    const data: FormatAnnotationResponseData[] = [];

    if (!annotationData || annotationData.data.length === 0) {
        return data;
    }

    chartData[0].data.forEach(chartDataItem => {
        const chartDataDate = moment(new Date(chartDataItem[0] as unknown as number)).format('YYYY-MM-DD');

        const matchedAnnotation = annotationData.data.find(dataItem => dataItem.date === chartDataDate);

        if (matchedAnnotation) {
            data.push({
                name: matchedAnnotation.name,
                data: chartDataItem,
            });
        }
    });

    return data;
};

export const formatAnnotationsChartConfig = (annotationData: AnnotationConfig) => {
    const annotationsConfig: ExtendedAnnotationConfigItem[] = [];

    if (!annotationData || annotationData.length === 0) {
        return annotationsConfig;
    }

    annotationData.forEach(annotationDataItem => {
        if (annotationDataItem.checked) {
            annotationDataItem.data.forEach(dataItem => {
                annotationsConfig.push({
                    draggable: true,
                    labelOptions: {
                        backgroundColor: '#1e1c1c',
                        borderRadius: 10,
                        borderWidth: 1,
                        allowOverlap: true,
                    },
                    labels: [
                        {
                            point: {
                                xAxis: 0,
                                yAxis: 0,
                                x: dataItem.data[0],
                                y: dataItem.data[1],
                            },
                            text: dataItem.name,
                            style: {
                                width: 'fit-content',
                                cursor: 'pointer',
                            },
                        },
                    ],
                });
            });
        }
    });

    return annotationsConfig;
};
