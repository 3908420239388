export const pieChartConfig = {
    options: {
        chart: {
            type: 'pie',
        },
        title: {
            text: '',
        },
        credits: {
            enabled: false,
        },
        xAxis: {
            title: '',
        },
        yAxis: {
            title: '',
        },
        legend: {
            enabled: false,
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '{point.y:.2f}%',
                },
                showInLegend: false,
                borderRadius: 0,
            },
            series: {
                animation: true,
            },
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{points.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%<br/>',
        },
        series: [
            {
                data: [],
            },
        ],
        reflow: true,
    },
};
