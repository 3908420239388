import React from 'react';

// Components
import Legend from './components/legend';
import LegendEmpty from './components/legend-empty';
import LegendItem from './components/legend-item';
import LegendItemList from './components/legend-item-list';
import LegendItemListContent from './components/legend-item-list-content';
import LegendItemListCancel from './components/legend-item-list-cancel';

// Context
import { SelectionLegendProvider } from './context/selection-legend-context';

// Types
import { DataTableSelectedRows } from '../../types';

export type SelectionLegendProps = {
    selectedRows: DataTableSelectedRows[];
    children: React.ReactNode;
};

const SelectionLegend = ({ selectedRows, children }: SelectionLegendProps) => {
    return <SelectionLegendProvider selectedRows={selectedRows}>{children}</SelectionLegendProvider>;
};

SelectionLegend.Legend = Legend;
SelectionLegend.LegendEmpty = LegendEmpty;
SelectionLegend.LegendItem = LegendItem;
SelectionLegend.LegendItemList = LegendItemList;
SelectionLegend.LegendItemListContent = LegendItemListContent;
SelectionLegend.LegendItemListCancel = LegendItemListCancel;

export default SelectionLegend;
