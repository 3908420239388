import React from 'react';

import { useTableContext } from '../context/table-context';

const Header = ({ children }: { children: React.ReactNode }) => {
    const { status } = useTableContext();

    if (status) {
        return <thead>{children}</thead>;
    }

    return null;
};

export default Header;
