/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { debounce } from 'lodash';

// Redux
import { useAppSelector } from '../redux/hooks';

// Components
import Table from './components/table/table';
import Graph from './components/graph/graph';
import GenericTableRow from './components/table/generic-table-row';
import Dropdown from '../components/dropdown-component';
import Breadcrumb from './components/breadcrumb/breadcrumb';
import SelectionLegend from './components/selection-legend/selection-legend';
import Cancel from '../components/cancel';
import IconChevronRightThin from '../components/icons/chevron-right-thin';
import IconChevronLeftThin from '../components/icons/chevron-left-thin';
import GraphMetricsDropdown from './components/graph-metrics-dropdown/graph-metrics-dropdown';

// Context
import { ReportViewProvider, useReportViewContext } from './context/report-view-context';
import { FilterBarContext } from '../filter-bar/context/filter-bar-context';

// Helpers
import {
    addCheckboxColumn,
    generateTablePaginationsList,
    generateTableParams,
    getColumnTotal,
    getNextDimension,
    getProportions,
    getTotals,
} from './helpers/table-helpers';
import { createArrayOfDates, mapDataToSeries, setUniqueArray } from '../helpers/utils';
import { excludeBreadcrumbs } from '../helpers/exclude-breadcrumbs';
import { generateFieldOptions } from './helpers/breadcrumb-helpers';
import { assignColour, channelColours, newColourAsRgba } from '../helpers/colours';
import { generateGraphRequests } from './helpers/graph-helper';
import { formatSalesTitle } from '../helpers/formatGlobalSaleTitle';
import { formatAnnotationResponse } from './helpers/annotation-helper';

// Types
import {
    AnnotationConfig,
    ChartData,
    ChartDateGranularityTypes,
    ChartFillColor,
    ChartSeriesData,
    ChartType,
    ChartXAxisDataPointParam,
    ChartXAxisDataPoints,
    DataTableCellProperties,
    DataTableColumn,
    DataTableData,
    DataTableDataObject,
    DataTableMetaObject,
    DataTableMetricData,
    DataTableSelectedRows,
    DimensionFilters,
    MenuRadioOption,
    NextDimension,
    OrderByDir,
    ReportConfig,
    TableExportParam,
} from './types';
import { CubedField, CubedResource } from '../types';

// React Query
import { ConfigDataSuccess } from '../react-query/types';
import useFetchResourceReportView from '../react-query/hooks/use-fetch-resource-report-view';
import useFetchResourcesReportView from '../react-query/hooks/use-fetch-resources-report-view';

// Configurations
import { ChartDateGranularity } from '../configurations/common/chart-types';

// Enums
import dataTypes from '../filter-bar/enums/data-types';
import { formatDates } from '../helpers/format-dates';

// Styles
const StyledLayout = styled.div<{ hideMain: boolean }>`
    display: grid;
    grid-template-areas: ${props => (props.hideMain ? `'breadcrumb' 'table'` : `'breadcrumb' 'main' 'table'`)};
    grid-template-rows: ${props => (props.hideMain ? 'auto auto' : 'auto 1fr auto')};
    gap: 1rem;
    padding: 0.7rem;
    background-color: ${props => props.theme.sectionDashboard.widget.menu.backgroundColor};
    ${props => props.theme.boxShadow(1)}
    min-height: ${props => !props.hideMain && '660px'};
    height: 100%;
    overflow: hidden;
`;

const StyledBreadcrumb = styled.div`
    grid-area: breadcrumb;
    width: 100%;

    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap-reverse;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: flex-start;
        gap: 0.7rem;
        flex-wrap: nowrap;
    }
`;

const StyledMainSection = styled.div<{ hideDropdown?: boolean }>`
    grid-area: main;
    display: grid;
    grid-template-columns: ${props => (!props.hideDropdown ? `1fr 2.5fr` : `1fr`)};
    gap: 1rem;
    width: 100%;
    box-sizing: border-box;

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
        height: auto;
    }
`;

const StyledFilterSection = styled.div``;

const StyledGraphMetricsDropdown = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
`;

const StyledGraphSection = styled.div`
    width: 100%;
    overflow: hidden;
`;

const StyledTableSection = styled.div`
    grid-area: table;
    width: 100%;
    min-height: 200px;
    overflow-x: auto;
    overflow-y: hidden;
`;

const StyledTableTabSection = styled.div`
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    gap: 15px 0px;
`;

const StyledPaginationLeftIcon = styled(IconChevronLeftThin)`
    height: 14px;
    width: 14px;
`;

const StyledPaginationRightIcon = styled(IconChevronRightThin)`
    height: 14px;
    width: 14px;
`;

const StyledSeparator = styled.div`
    width: 100%;
    height: 1px;
    border: none;
    background: ${props => props.theme.sectionDashboard.widget.menu.separatorColor};
    margin: 15px 0px 5px 0px;
`;

const ReportViewLayout = () => {
    const {
        // Report
        config,
        modifiedConfig,
        selectedProducts,
        selectedMetrics,
        startDate,
        endDate,
        // Tabs
        tabs,
        selectTab,
        // Tables
        tableOrderBy,
        setTableOrderBy,
        tableOrderDir,
        setTableOrderDir,
        handleSetCurrentOrder,
        // Paginations
        initialPaginations,
        initialPaginationsList,
        tablePagination,
        setTablePagination,
        tablePaginationsList,
        setTablePaginationsList,
        handlePageRowOptionsClick,
        handlePageListItemClick,
        handlePageIncreaseItemClick,
        // Charts
        selectedChartType,
        dateGranularityRadioOptions,
        // Chart Filteration
        annotationConfig,
        chartDateGranularity,
        setChartDateGranularity,
        annotations,
        setAnnotations,
        annotationResponse,
    } = useReportViewContext();

    // Filter bar configurations
    const { setFilterStatus, setDatePickerConfig, setFilterMetricsOptions } = useContext(FilterBarContext);

    const {
        filters,
        resources,
        table: { tabListItems, fieldChildren, hideTotals },
        hideDropdown,
        showDropdownLegend,
        dateDimension,
        allDataExport,
    } = config;

    const { chart } = modifiedConfig;

    const { globalSalesTitle } = useAppSelector(state => state.saleTitle);

    const selectedTab = tabs.find(tab => tab.isSelected)?.key;

    // Date
    const [dateFormatted, setDateFormatted] = useState(formatDates(startDate, endDate, config.resources[0]));

    // Report view states
    const [dimensionFilters, setDimensionFilters] = useState<DimensionFilters[]>([]);
    const [resource, setResource] = useState(config.resources[0]);
    const [searchValue, setSearchValue] = useState('');
    const [debouncedSearchValue, setDebouncedSearchValue] = useState('');

    // Table states
    const [metrics, setMetrics] = useState<DataTableColumn[]>(tabListItems[selectedTab ?? 0]?.metrics || []);
    const [columns, setColumns] = useState<DataTableColumn[]>([]);
    const [previousColumn, setPreviousColumn] = useState<DataTableColumn[]>([]);
    const [tableInitialAllData, setTableInitialAllData] = useState<DataTableData>();
    const [tableInitialData, setTableInitialData] = useState<DataTableDataObject[]>([]);
    const [tableData, setTableData] = useState<DataTableDataObject[]>([]);
    const [tableTotalData, setTableTotalData] = useState<DataTableMetaObject | {}>({});
    const [tableGroupBy, setTableGroupBy] = useState<DataTableColumn[]>(resources[0]?.defaultDimensions ?? []);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<DataTableSelectedRows[]>([]);
    const [tableParams, setTableParams] = useState(
        generateTableParams({
            config,
            dimensionFilters,
            page: tablePagination.currentPage,
            resource,
            rowCount: tablePagination.rowDefault,
            searchValue: debouncedSearchValue,
            selectedProducts,
            tableGroupBy,
            tableOrderBy,
            tableOrderDir,
            startDate,
            endDate,
            selectedMetrics,
        })
    );

    // Breadcumb states
    const [breadcrumbResources, setBreadcrumbResources] = useState(resources);
    const [breadcrumbsFieldsOptions, setBreadcrumbsFieldsOptions] = useState(
        generateFieldOptions(dimensionFilters, breadcrumbResources)
    );

    // Chart (Graph) states
    const [chartYMetrics, setChartYMetrics] = useState(chart?.initialYMetrics || []);
    const [chartXMetric, setChartXMetric] = useState<CubedField | null>(
        chart?.initialXMetric || resources[0].dateDimension || null
    );

    const [chartRequest, setChartParamsRequest] = useState(
        selectedChartType !== undefined
            ? generateGraphRequests({
                  config,
                  dimensionFilters,
                  page: tablePagination.currentPage,
                  resource,
                  rowCount: tablePagination.rowDefault,
                  searchValue: debouncedSearchValue,
                  selectedProducts,
                  selectedMetrics,
                  selectedRows,
                  tableGroupBy,
                  tableOrderBy,
                  tableOrderDir,
                  xMetric: chartXMetric,
                  yMetrics: chartYMetrics,
                  startDate,
                  endDate,
                  chartDateGranularity,
              })
            : []
    );
    const [chartSeriesData, setChartSeriesData] = useState<ChartSeriesData[]>([]);
    const [isChartEmpty, setIsChartEmpty] = useState(true);

    // Table export states
    const [exportParams, setexportParams] = useState<TableExportParam>();

    // Queries
    const tableDataQuery = useFetchResourceReportView({
        resource: resource,
        params: [
            ...tableParams,
            {
                key: 'totals',
                value: false,
            },
        ],
        select: (data: ConfigDataSuccess) => {
            return data;
        },
    });

    const tableTotalDataQuery = useFetchResourceReportView({
        resource: resource,
        params: [
            ...tableParams,
            {
                key: 'totals',
                value: true,
            },
        ],
        select: (data: ConfigDataSuccess) => {
            return data;
        },
    });

    const chartDataQuery = useFetchResourcesReportView(
        chartRequest.map(request => ({
            resource: request.resource,
            params: request.params.map(param => ({
                key: param.key,
                value: param.value as string | number | boolean,
            })),
            staleTime: 1000 * 60 * 10,
            enabled: true,
            isPaginated: false,
            select: (data: ConfigDataSuccess) => {
                return data;
            },
        }))
    );

    useEffect(() => {
        // Filter bar configurations
        let metricData: DataTableMetricData[] = [];
        tabListItems.forEach(data => {
            data.metrics.forEach(metric => {
                metricData.push({
                    ...metric,
                    value: metric.rawName,
                    label: metric.displayName,
                });
            });
        });
        setFilterStatus(filters.status); // reset filter status
        setDatePickerConfig(filters.datePicker || {}); // reset datepicker
        setFilterMetricsOptions([...(setUniqueArray(metricData, 'rawName') as never)]); // reset metrics

        if (fieldChildren) {
            assignAllChildren();
        }

        setDateFormatted(formatDates(startDate, endDate, resource));
    }, []);

    useEffect(() => {
        setDateFormatted(formatDates(startDate, endDate, resource));
    }, [startDate, endDate, resource]);

    // Tab select
    useEffect(() => {
        const metricsData = tabListItems[selectedTab ?? 0]?.metrics;
        setMetrics(metricsData);
        setColumns(addCheckboxColumn([...tableGroupBy, ...metricsData] as DataTableColumn[]));
    }, [selectedTab, tableGroupBy]);

    useEffect(() => {
        const tableParams = generateTableParams({
            config,
            dimensionFilters,
            page: tablePagination.currentPage,
            resource,
            rowCount: tablePagination.rowDefault,
            searchValue: debouncedSearchValue,
            selectedProducts,
            tableGroupBy,
            tableOrderBy,
            tableOrderDir,
            startDate,
            endDate,
            selectedMetrics,
        });

        if (selectedChartType !== undefined) {
            const chartParams = generateGraphRequests({
                config,
                dimensionFilters,
                page: tablePagination.currentPage,
                resource,
                rowCount: tablePagination.rowDefault,
                searchValue: debouncedSearchValue,
                selectedProducts,
                selectedMetrics,
                selectedRows,
                tableGroupBy,
                tableOrderBy,
                tableOrderDir,
                xMetric: chartXMetric,
                yMetrics: chartYMetrics,
                startDate,
                endDate,
                chartDateGranularity,
            });

            setChartParamsRequest(chartParams);
        }

        setTableParams(tableParams);
        generateTableExportParams(resource.category, resource.id, tableParams);
    }, [
        config,
        dimensionFilters,
        tablePagination,
        resource,
        debouncedSearchValue,
        selectedProducts,
        tableGroupBy,
        tableOrderBy,
        tableOrderDir,
        startDate,
        endDate,
        selectedMetrics,
        selectedRows,
        chartXMetric,
        chartYMetrics,
        chartDateGranularity,
    ]);

    // Fetch table data
    useEffect(() => {
        if (tableDataQuery.data && tableTotalDataQuery.data) {
            const modifiedTableDataObjects = [...tableDataQuery.data.objects, ...tableTotalDataQuery.data.objects];
            const modifiedTableDataMeta = {
                ...tableDataQuery.data.meta,
                ...tableTotalDataQuery.data.meta,
            };
            const modifiedTableData = {
                objects: modifiedTableDataObjects,
                meta: modifiedTableDataMeta,
            };

            const totals = getTotals(modifiedTableData, tableGroupBy, tabListItems[selectedTab as number].metrics);
            const newData = getProportions(
                modifiedTableData,
                totals,
                tableGroupBy,
                tabListItems[selectedTab as number].metrics
            );
            const totalCount = newData.meta ? newData.meta.total_count : 0;
            const rowDefaultValue = newData.meta ? newData.meta.limit : 10;

            const newPagination = {
                ...tablePagination,
                totalResults: totalCount,
                currentRange: [
                    (tablePagination.currentPage - 1) * rowDefaultValue + 1,
                    Math.min(tablePagination.currentPage * rowDefaultValue, totalCount),
                ],
                rowDefault: rowDefaultValue,
            };

            selectTab(0);
            setTableInitialAllData(newData as DataTableData);
            setTableInitialData(newData.objects as DataTableDataObject[]);
            setTableData(newData.objects as DataTableDataObject[]);
            setTableTotalData(newData.meta as unknown as DataTableMetaObject);
            setTablePagination(newPagination);
            setTablePaginationsList(
                generateTablePaginationsList(
                    newPagination.currentPage,
                    newPagination.maxButtons,
                    newPagination.minPage,
                    newPagination.totalResults,
                    newPagination.rowDefault
                )
            );
        }
    }, [tableDataQuery.data, tableTotalDataQuery.data]);

    // Fetch chart(graph) data
    useEffect(() => {
        if (chartDataQuery.data) {
            const seriesList: ChartSeriesData[] = [];

            const allChartDataEmpty = chartDataQuery.data.every(response => response?.objects.length === 0);
            setIsChartEmpty(allChartDataEmpty);

            chartDataQuery.data.forEach((response, index) => {
                if (response) {
                    const thisConfig = {
                        ...chart?.options?.seriesConfig,
                    };

                    let xAxisDataPoints: ChartXAxisDataPoints = [];
                    let graphData: ChartData[] = [];

                    if (chartXMetric && [dataTypes.DATE, dataTypes.DATE_TIME].includes(chartXMetric.dataType)) {
                        xAxisDataPoints = createArrayOfDates(startDate, endDate, chartDateGranularity);
                    } else if (chartXMetric) {
                        xAxisDataPoints = response?.objects?.map(object => {
                            return object[chartXMetric.rawName as keyof typeof object];
                        }) as unknown as ChartXAxisDataPointParam[];
                    }

                    if (chart.dataFormatter) {
                        let groupbyOveride = resource.graphGroupByOverride;
                        let dim = dateDimension;
                        if (groupbyOveride) {
                            dim = groupbyOveride[0];
                        }

                        chartYMetrics.forEach(metric => {
                            if (chart.dataFormatter) {
                                const seriesGraphData = chart.dataFormatter(
                                    xAxisDataPoints,
                                    response?.objects as unknown as DataTableDataObject[],
                                    dim,
                                    metric.rawName
                                );

                                if (chart.skippedPlotNullValues) {
                                    seriesGraphData.forEach(element => {
                                        if (typeof element[1] === 'number' && element[1] === 0) {
                                            return [...element, ((element[1] as null) = null)];
                                        }
                                    });
                                }

                                graphData.push(seriesGraphData);
                            }
                        });
                    } else {
                        chartYMetrics.forEach(metric => {
                            const seriesData = mapDataToSeries(
                                xAxisDataPoints,
                                response?.objects,
                                chartXMetric,
                                metric.rawName,
                                chartDateGranularity
                            );
                            graphData.push(seriesData);
                        });
                    }

                    const alpha = chartYMetrics.length > 1 ? 0.3 : 0.5;

                    if (selectedChartType === 'line' || selectedChartType === 'areaSpline') {
                        thisConfig.fillColor = {
                            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                            stops: [
                                [0, newColourAsRgba(chartRequest[index].colour, alpha)],
                                [1, newColourAsRgba(chartRequest[index].colour, 0)],
                            ],
                        };
                    } else {
                        thisConfig.fillColor = null;
                    }

                    if (selectedChartType === 'line' || selectedChartType === 'areaSpline') {
                        thisConfig.color = newColourAsRgba(chartRequest[index].colour, 0.5);
                    } else {
                        thisConfig.color = null;
                    }

                    chartYMetrics.forEach((metric, metricIndex) => {
                        let fillColor =
                            selectedChartType === 'line' || selectedChartType === 'areaSpline'
                                ? metricIndex > 0
                                    ? {
                                          linearGradient: {
                                              x1: 0,
                                              y1: 0,
                                              x2: 0,
                                              y2: 0,
                                          },
                                          stops: [
                                              [0, 'rgba(255,0,0,0)'],
                                              [1, 'rgba(255,0,0,0)'],
                                          ],
                                      }
                                    : thisConfig.fillColor
                                : null;

                        seriesList.push({
                            ...thisConfig,
                            data: graphData[metricIndex],
                            filter: chartRequest[index].filter,
                            name: formatSalesTitle(metric.displayName, globalSalesTitle),
                            dashStyle: metricIndex > 0 ? 'Dash' : null,
                            fillColor: fillColor as ChartFillColor,
                        });
                    });
                }
            });

            setChartSeriesData(seriesList);
        }
    }, [chartDataQuery]);

    // Update breadcrumb resources
    useEffect(() => {
        const updatedResources = excludeBreadcrumbs(dimensionFilters, config.resources, resource);
        setBreadcrumbResources(updatedResources);
    }, [dimensionFilters]);

    // Update breadcrumb demensions
    useEffect(() => {
        setBreadcrumbsFieldsOptions(generateFieldOptions(dimensionFilters, breadcrumbResources));
    }, [breadcrumbResources, dimensionFilters]);

    // Update chart data with annotations
    useEffect(() => {
        // when we have the chart data from table-graph we can match the chart data with the annotation data
        if (
            chartDateGranularity !== ChartDateGranularity.Month &&
            chartSeriesData.length > 0 &&
            annotationConfig.length > 0 &&
            annotationResponse
        ) {
            let annotationsToUpdate = [...annotations];

            const updatedAnnotations = annotationsToUpdate.map(updateItem => {
                let annotationResponseItem = annotationResponse.filter(
                    responseItem => responseItem.id === updateItem.id
                )[0];

                return {
                    ...updateItem,
                    disabled: annotationResponseItem ? false : true,
                    data: annotationResponseItem
                        ? formatAnnotationResponse(chartSeriesData, annotationResponseItem)
                        : [],
                };
            });

            setAnnotations(updatedAnnotations as unknown as AnnotationConfig);
        }
    }, [chartSeriesData, annotationResponse]);

    // Table data update handler
    const updateTableData = (data: DataTableDataObject) => {
        const rowId = data.__id;

        setTableData(prevData => {
            const selectedCount = selectedRowIds.length;
            const isRowSelected = prevData.find(row => row.__id === rowId)?.selected ?? false;

            if (selectedCount >= 5 && !isRowSelected) {
                // Deselect the first selected row
                const [firstSelectedRowId, ...remainingSelectedRowIds] = selectedRowIds;

                return prevData.map(row => {
                    if (row.__id === rowId) {
                        setSelectedRowIds([...remainingSelectedRowIds, rowId]);
                        setSelectedRows([
                            ...selectedRows.filter(selectedRow => selectedRow.rowId !== firstSelectedRowId),
                            {
                                data,
                                rowId,
                                dimensions: tableGroupBy,
                                colour: assignColour(data, tableGroupBy, selectedRows),
                            },
                        ]);
                        return { ...row, selected: true };
                    } else if (row.__id === firstSelectedRowId) {
                        return { ...row, selected: false };
                    }

                    return row;
                }) as DataTableDataObject[];
            }

            return prevData.map(row => {
                if (row.__id === rowId) {
                    if (row.selected) {
                        setSelectedRowIds(selectedRowIds.filter(id => id !== rowId));
                        setSelectedRows(selectedRows.filter(selectedRow => selectedRow.rowId !== rowId));
                    } else {
                        setSelectedRowIds([...selectedRowIds, rowId]);
                        setSelectedRows([
                            ...selectedRows,
                            {
                                data,
                                rowId,
                                dimensions: tableGroupBy,
                                colour: assignColour(data, tableGroupBy, selectedRows),
                            },
                        ]);
                    }

                    return { ...row, selected: !row.selected };
                }

                return row;
            }) as DataTableDataObject[];
        });
    };

    // Table select checkbox handler
    const handleCheckboxChange = (data: DataTableDataObject) => {
        updateTableData(data);
    };

    // Table assign all children
    const assignAllChildren = () => {
        for (let resource of resources) {
            if (resource.dimensions) {
                for (let field of resource.dimensions as DataTableColumn[]) {
                    field.dataOverrides = config.table.fieldDataOverrides[field.id];
                    field.parentOverride = config.table.fieldParentOverrides[field.id];
                    field.children = config.table.fieldChildren[field.id];
                }
            }
        }

        const modifiedResources = resources;
        setTableGroupBy(modifiedResources[0].defaultDimensions || []);
        setColumns(addCheckboxColumn([...tableGroupBy, ...metrics] as DataTableColumn[]));
    };

    // Table dimension click handler
    const handleDimensionClick = (
        event: React.MouseEvent<HTMLElement>,
        column: DataTableColumn,
        properties: DataTableCellProperties,
        clearFilters?: boolean
    ) => {
        event.stopPropagation();

        if (!column.children) return;

        setPreviousColumn([...tableGroupBy, ...(tabListItems[selectedTab ?? 0]?.metrics || [])]);

        let nextDimension = getNextDimension(
            column,
            properties as DataTableCellProperties,
            resource,
            dimensionFilters
        ) as NextDimension;
        if (!nextDimension) return;

        const mainFieldValue = properties.raw_value !== undefined ? properties.raw_value : properties.value;

        const mainField = {
            field: column,
            value: mainFieldValue,
            fuzzy: false,
            resource: resource,
            displayValue: properties.value,
        };

        let orderField = column !== tableOrderBy ? tableOrderBy : nextDimension.field;

        if (nextDimension.resources?.forceDefaultOrderBy) {
            orderField = nextDimension.resources.defaultOrderBy as CubedField;
        }

        let orderDir = column !== tableOrderBy ? tableOrderDir : nextDimension.field.defaultOrderDir;

        if (nextDimension.resources?.forceDefaultOrderDir) {
            orderDir = orderField.defaultOrderDir;
        }

        // if our nextDimension has no resource (for dimensions that may belong to multiple resources,
        // then just use current resource. Otherwise, use the dimension's resource)
        let nextResource = nextDimension.resource || resource;
        // Remove any filters that do not fit with our current dimensions.
        let validFilters = dimensionFilters.filter(filter => {
            return (
                nextResource.dimensions &&
                nextResource.dimensions.find(dimension => dimension.rawName === filter.field.rawName)
            );
        });

        setResource(nextResource);
        setTableGroupBy([nextDimension.field]);
        setTableOrderBy(orderField);
        setTableOrderDir(orderDir as OrderByDir);
        setSearchValue('');
        setDebouncedSearchValue('');
        setDimensionFilters(clearFilters ? [mainField] : ([...validFilters, mainField] as any));
        setTablePagination(initialPaginations);
        setTablePaginationsList(initialPaginationsList);
        setSelectedRows([]);
        setSelectedRowIds([]);
        setTableData(tableInitialData);
    };

    // Table search handlers
    const handleTableSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchValue(event.target.value);
        debouncedHandleTableSearchOnChange(event.target.value);
    };

    const searchOnChange = (value: string) => setDebouncedSearchValue(value);

    const debouncedHandleTableSearchOnChange = useCallback(debounce(searchOnChange, 500), []);

    // Table search clear handler
    const handleTableSearchClearOnClick = () => {
        setSearchValue('');
        setDebouncedSearchValue('');
    };

    // Breadcrumb home click handler
    const handleBreadcrumbHomeClick = () => {
        // Reset all filters
        setResource(config.resources[0]);
        setTableOrderBy(resources[0].defaultOrderBy as CubedField);
        setTableOrderDir(resources[0].defaultOrderBy?.defaultOrderDir as OrderByDir);
        setTableGroupBy(resources[0]?.defaultDimensions ?? []);
        setDimensionFilters([]);
        setSearchValue('');
        setDebouncedSearchValue('');
        setTablePagination(initialPaginations);
        setTablePaginationsList(initialPaginationsList);
        setSelectedRows([]);
        setSelectedRowIds([]);
        setTableData(tableInitialData);
    };

    // Breadcrumb resource and field handler
    const handleSetResourceAndField = (
        selectedResourceField: CubedResource,
        field: CubedField,
        filter?: DimensionFilters
    ) => {
        let selectedResource = selectedResourceField;

        if (filter && filter.resource) {
            selectedResource = filter.resource;
        }

        let validFilters = [];

        // traverse the current dimensionFilters - reset the lowest point to current selected Field
        for (var i = 0; i < dimensionFilters.length; i++) {
            let filter = dimensionFilters[i];
            if (filter.field.rawName === field.rawName) {
                break;
            }
            validFilters.push(filter);
        }

        const previousOrderByColumnMatches = previousColumn.some(item => {
            if (item.displayName === tableOrderBy.displayName) {
                return true;
            }
            return false;
        });

        if (!previousOrderByColumnMatches) {
            setTableOrderBy(selectedResource.defaultOrderBy as CubedField);
            setTableOrderDir(selectedResource.defaultOrderBy?.defaultOrderDir as OrderByDir);
        }

        if (field.rawName !== tableGroupBy[0].rawName) {
            setSelectedRows([]);
            setSelectedRowIds([]);
            setTableData(tableInitialData);
        }

        setResource(selectedResource);
        setTableGroupBy([field]);
        setDimensionFilters([...validFilters]);
        setSearchValue('');
        setDebouncedSearchValue('');
        setTablePagination(initialPaginations);
        setTablePaginationsList(initialPaginationsList);
    };

    // Breadcrumb remove filter handler
    const handleRemoveFilter = (field: CubedField) => {
        setDimensionFilters([...dimensionFilters].filter(filter => filter.field !== field));
        setSelectedRows([]);
        setSelectedRowIds([]);
        setTableData(tableInitialData);
    };

    // Breadcrumb selected breadcrumb cancel handler
    const handleSelectedBreadcrumbCancel = () => {
        const filter = dimensionFilters[dimensionFilters.length - 1];
        handleSetResourceAndField(resource, filter.field, filter);
        handleRemoveFilter(filter.field);
    };

    // Selection Legend handlers
    const handleRemoveSelectionLegend = (selectedRowsData: DataTableSelectedRows[], rowId?: number | string | null) => {
        const rows = selectedRowsData.find(row => row.rowId === rowId);
        if (rows?.data) {
            updateTableData(rows.data);
        }
    };

    // Chart Y metrics on select handler
    const handleChartYMetricOnSelect = (value: string, metricIndex: number) => {
        if (chart) {
            let thisMetric = chartYMetrics[metricIndex];
            for (let metric of chart.allYMetrics) {
                if (metric.rawName === value) {
                    thisMetric = metric;
                }
            }

            let newMetrics = [...chartYMetrics];
            newMetrics[metricIndex] = thisMetric;
            setChartYMetrics(newMetrics);
        }
    };

    // Chart X metrics on select handler
    const handleChartXMetricOnSelect = (value: string) => {
        const thisMetric = chart.allXMetrics?.filter(metric => {
            return metric.rawName === value;
        });

        setChartXMetric(thisMetric ? thisMetric[0] : null);
    };

    // Table selected row reset handler
    const handelResetSelectedRows = () => {
        setSelectedRows([]);
        setSelectedRowIds([]);
        setTableData(tableInitialData);
    };

    // Chart granularity radio on select handler
    const handleUpdateChartDateGranularity = (type: string) => {
        const start = moment.utc(startDate.format('YYYY-MM-DD'));
        const end = moment.utc(endDate.format('YYYY-MM-DD'));

        if (type === ChartDateGranularity.Day && start.isSame(end, 'date')) {
            type = ChartDateGranularity.Hour;
        }

        setChartDateGranularity(type as ChartDateGranularityTypes);
    };

    // Chart annotation visibility handler
    const handleChangeAnnotationVisibility = (id: string) => {
        const updatedAnnotations = [...annotations].map(annotation => {
            if (annotation.id === id) {
                return {
                    ...annotation,
                    checked: !annotation.checked,
                };
            }
            return annotation;
        });

        setAnnotations(updatedAnnotations);
    };

    // Table data export params
    const generateTableExportParams = (
        resourceGroup: string,
        resourceName: string,
        params: {
            key: string;
            value: any;
        }[]
    ) => {
        const formattedParams = [...params];
        const dateDim = resource.dateDimension;
        let filename = resourceName;
        if (dateDim) {
            const startDate = formattedParams.filter(item => item.key === `${dateDim.rawName}__gte`)[0];
            const endDate = formattedParams.filter(item => item.key === `${dateDim.rawName}__lte`)[0];
            filename += `_${moment(startDate.value).format('DD-MM-YYYY')}_${moment(endDate.value).format(
                'DD-MM-YYYY'
            )}`;
        }

        setexportParams({
            resourceGroup,
            resourceName,
            params: [{ key: 'filename', value: filename }, ...formattedParams],
        });
    };

    if (
        (tableDataQuery.status === 'success' || tableDataQuery.status === 'pending') &&
        (tableTotalDataQuery.status === 'success' || tableTotalDataQuery.status === 'pending')
    ) {
        return (
            <StyledLayout hideMain={selectedChartType === undefined}>
                <StyledBreadcrumb>
                    <Breadcrumb
                        status={tableDataQuery.status || tableTotalDataQuery.status}
                        isFetching={tableDataQuery.isFetching || tableTotalDataQuery.isFetching}
                        disabled={tableDataQuery.status === 'pending' || tableTotalDataQuery.status === 'pending'}
                        empty={tableData.length === 0}
                    >
                        {/* Breadcrumb Dimension select */}
                        <Breadcrumb.DimensionSelectBox>
                            <Breadcrumb.Placeholder />

                            {/* Content */}
                            <Breadcrumb.DimensionSelectBoxContent>
                                <Breadcrumb.DimensionSelectBoxContentItem>
                                    {tableGroupBy[0].displayName}
                                </Breadcrumb.DimensionSelectBoxContentItem>

                                <Breadcrumb.DimensionSelectBoxContentDropdownIcon />

                                {dimensionFilters[dimensionFilters.length - 1] && (
                                    <Cancel onClick={handleSelectedBreadcrumbCancel} colour="white" floatRight={true} />
                                )}
                            </Breadcrumb.DimensionSelectBoxContent>

                            {/* Dropdown */}
                            <Breadcrumb.DimensionSelectBoxOptions>
                                {breadcrumbsFieldsOptions.map(option => {
                                    return (
                                        <div key={option.id}>
                                            <Breadcrumb.DimensionSelectBoxOptionsResources>
                                                {option.resource.displayName}
                                            </Breadcrumb.DimensionSelectBoxOptionsResources>

                                            {option.fields.map(field => {
                                                return (
                                                    <Breadcrumb.DimensionSelectBoxOptionsFields
                                                        resource={option.resource}
                                                        field={field}
                                                        handleSetResourceAndField={handleSetResourceAndField}
                                                        key={field.id}
                                                    >
                                                        {field.displayName}
                                                    </Breadcrumb.DimensionSelectBoxOptionsFields>
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                            </Breadcrumb.DimensionSelectBoxOptions>
                        </Breadcrumb.DimensionSelectBox>

                        {/* Breadcrumb Dimension search */}
                        {dimensionFilters
                            .slice()
                            .reverse()
                            .map((filter, index) => (
                                <Breadcrumb.DimensionSearchBox
                                    key={`${index}${filter.value}`}
                                    filter={filter}
                                    startDate={startDate}
                                    endDate={endDate}
                                    dimensionFilters={dimensionFilters}
                                    resource={resource}
                                    setDimensionFilters={setDimensionFilters}
                                    setTableGroupBy={setTableGroupBy}
                                    handleRemoveFilter={handleRemoveFilter}
                                >
                                    <Breadcrumb.DimensionSearchBox.DimensionSearchBoxContent>
                                        <Breadcrumb.Placeholder data-testid="selected-breadcrumb-item-label">
                                            {filter.field.displayName}
                                        </Breadcrumb.Placeholder>
                                        <Breadcrumb.DimensionSearchBox.DimensionSearchBoxContentItem />
                                        <Breadcrumb.DimensionSearchBox.DimensionSearchBoxOptions />
                                    </Breadcrumb.DimensionSearchBox.DimensionSearchBoxContent>
                                </Breadcrumb.DimensionSearchBox>
                            ))}

                        {/* Home */}
                        <Breadcrumb.Home onClick={handleBreadcrumbHomeClick} />
                    </Breadcrumb>
                </StyledBreadcrumb>

                {selectedChartType !== undefined && (
                    <StyledMainSection hideDropdown={hideDropdown}>
                        {!hideDropdown && (
                            <StyledFilterSection>
                                {/* Y-Metrics dropdown */}
                                <GraphMetricsDropdown>
                                    <GraphMetricsDropdown.Dropdown>
                                        {chart.allXMetrics && (
                                            <GraphMetricsDropdown.DropdownTitle>
                                                Y-Axis
                                            </GraphMetricsDropdown.DropdownTitle>
                                        )}
                                        {chartYMetrics.map((metric, index) => (
                                            <StyledGraphMetricsDropdown>
                                                {showDropdownLegend && (
                                                    <GraphMetricsDropdown.DropdownLegend
                                                        legendStyle={index > 0 ? 'dashed' : 'solid'}
                                                    />
                                                )}

                                                <GraphMetricsDropdown.DropdownList
                                                    value={metric.rawName}
                                                    showDropdownLegend={showDropdownLegend}
                                                    onSelectChange={event =>
                                                        handleChartYMetricOnSelect(event.target.value, index)
                                                    }
                                                >
                                                    {chart.allYMetrics?.map((option, yIndex) => (
                                                        <GraphMetricsDropdown.DropdownListItems
                                                            key={yIndex}
                                                            option={option}
                                                        />
                                                    ))}
                                                </GraphMetricsDropdown.DropdownList>
                                            </StyledGraphMetricsDropdown>
                                        ))}
                                    </GraphMetricsDropdown.Dropdown>
                                </GraphMetricsDropdown>

                                {/* X-Metrics dropdown */}
                                {chart.allXMetrics && (
                                    <GraphMetricsDropdown>
                                        <GraphMetricsDropdown.Dropdown>
                                            {chart.allXMetrics && (
                                                <GraphMetricsDropdown.DropdownTitle>
                                                    X-Axis
                                                </GraphMetricsDropdown.DropdownTitle>
                                            )}
                                            <GraphMetricsDropdown.DropdownList
                                                value={chartXMetric?.rawName as string}
                                                onSelectChange={event => handleChartXMetricOnSelect(event.target.value)}
                                            >
                                                {chart.allXMetrics?.map((option, index) => (
                                                    <GraphMetricsDropdown.DropdownListItems
                                                        key={index}
                                                        option={option}
                                                    />
                                                ))}
                                            </GraphMetricsDropdown.DropdownList>
                                        </GraphMetricsDropdown.Dropdown>
                                    </GraphMetricsDropdown>
                                )}

                                <SelectionLegend selectedRows={selectedRows}>
                                    <SelectionLegend.Legend>
                                        <SelectionLegend.LegendItem>
                                            {/* All legend */}
                                            {selectedRows.length === 0 && (
                                                <SelectionLegend.LegendItemList
                                                    key={'all'}
                                                    isAll={true}
                                                    colour={channelColours[6].colour}
                                                    rowId={null}
                                                >
                                                    <SelectionLegend.LegendItemListContent
                                                        colour={channelColours[6].colour}
                                                        text={'All'}
                                                    />
                                                </SelectionLegend.LegendItemList>
                                            )}

                                            {/* Row legends */}
                                            {selectedRows.length > 0 &&
                                                selectedRows.map(row => (
                                                    <SelectionLegend.LegendItemList
                                                        key={row.rowId}
                                                        colour={row.colour}
                                                        rowId={row.rowId}
                                                    >
                                                        <SelectionLegend.LegendItemListContent
                                                            colour={row.colour}
                                                            text={row.data[tableGroupBy[0].rawName].value as string}
                                                        />
                                                        <SelectionLegend.LegendItemListCancel
                                                            rowId={row.rowId}
                                                            selectedRows={selectedRows}
                                                            onRemoveLegend={handleRemoveSelectionLegend}
                                                        />
                                                    </SelectionLegend.LegendItemList>
                                                ))}

                                            {/* Empty legends */}
                                            <SelectionLegend.LegendEmpty />
                                        </SelectionLegend.LegendItem>
                                    </SelectionLegend.Legend>
                                </SelectionLegend>
                            </StyledFilterSection>
                        )}

                        <StyledGraphSection>
                            <Graph
                                status={chartDataQuery.status}
                                isFetching={chartDataQuery.pending}
                                disabled={chartDataQuery.status === 'pending'}
                                empty={isChartEmpty}
                                startDate={dateFormatted.startDate}
                                endDate={dateFormatted.endDate}
                                chartXMetric={chartXMetric as CubedField}
                                selectedChartType={selectedChartType}
                                annotations={annotations}
                                handleChangeAnnotationVisibility={handleChangeAnnotationVisibility}
                            >
                                <Graph.TabList>
                                    {chart.allowedChartTypes.length > 0 && (
                                        <>
                                            {/* Graph tab list */}
                                            {chart.allowedChartTypes.map(type => (
                                                <Graph.TabListItem key={type} chartType={type as ChartType} />
                                            ))}

                                            {/* Graph filter */}
                                            <Graph.FilterWrapper disabled={false}>
                                                <Graph.FilterTitle>Date Granularity</Graph.FilterTitle>
                                                <Graph.FilterRadioGroup
                                                    value={chartDateGranularity}
                                                    onValueChange={handleUpdateChartDateGranularity}
                                                >
                                                    {dateGranularityRadioOptions.map((option: MenuRadioOption) => (
                                                        <Graph.FilterRadio
                                                            key={option.value}
                                                            option={option}
                                                            disabled={
                                                                chartDateGranularity === ChartDateGranularity.Hour
                                                            }
                                                        />
                                                    ))}
                                                </Graph.FilterRadioGroup>
                                                {chart?.annotations && chart?.annotations.length > 0 && (
                                                    <>
                                                        <StyledSeparator />
                                                        <Graph.FilterTitle>Annotations</Graph.FilterTitle>
                                                        {annotations &&
                                                            annotations.map(annotation => (
                                                                <Graph.FilterCheckbox
                                                                    key={annotation.id}
                                                                    id={annotation.id}
                                                                    label={annotation.name}
                                                                    checked={annotation.checked}
                                                                    disabled={annotation.disabled}
                                                                    onCheckedChange={() =>
                                                                        handleChangeAnnotationVisibility(annotation.id)
                                                                    }
                                                                />
                                                            ))}
                                                    </>
                                                )}
                                            </Graph.FilterWrapper>
                                        </>
                                    )}
                                </Graph.TabList>
                                <Graph.GraphWrapper>
                                    {(selectedChartType === 'line' || selectedChartType === 'areaSpline') && (
                                        <Graph.LineGraph
                                            chart={chart}
                                            chartXMetric={chartXMetric!}
                                            chartYMetrics={chartYMetrics}
                                            data={chartSeriesData}
                                            chartDateGranularity={chartDateGranularity}
                                        />
                                    )}
                                    {selectedChartType === 'trendLine' && (
                                        <Graph.TrendLineGraph
                                            chart={chart}
                                            chartXMetric={chartXMetric!}
                                            chartYMetrics={chartYMetrics}
                                            data={chartSeriesData}
                                            chartDateGranularity={chartDateGranularity}
                                        />
                                    )}
                                    {(selectedChartType === 'bar' || selectedChartType === 'barHorizontal') && (
                                        <Graph.BarGraph
                                            chart={chart}
                                            chartYMetrics={chartYMetrics}
                                            data={tableData ? tableData : []}
                                            dimensions={tableGroupBy}
                                            metrics={metrics}
                                            selectedRows={selectedRows}
                                        />
                                    )}
                                    {selectedChartType === 'pie' && (
                                        <Graph.PieGraph
                                            chart={chart}
                                            chartYMetrics={chartYMetrics}
                                            data={tableData ? tableData : []}
                                            tableInitialAllData={tableInitialAllData!}
                                            dimensions={tableGroupBy}
                                            metrics={metrics}
                                            selectedRows={selectedRows}
                                        />
                                    )}
                                    {selectedChartType === 'donut' && (
                                        <Graph.DoughnutGraph
                                            chart={chart}
                                            chartYMetrics={chartYMetrics}
                                            data={tableData ? tableData : []}
                                            tableInitialAllData={tableInitialAllData!}
                                            dimensions={tableGroupBy}
                                            metrics={metrics}
                                            selectedRows={selectedRows}
                                        />
                                    )}
                                </Graph.GraphWrapper>
                            </Graph>
                        </StyledGraphSection>
                    </StyledMainSection>
                )}

                {/* Table */}
                <StyledTableSection>
                    <Table
                        status={tableDataQuery.status || tableTotalDataQuery.status}
                        isFetching={tableDataQuery.isFetching || tableTotalDataQuery.isFetching}
                        disabled={tableDataQuery.status === 'pending' || tableTotalDataQuery.status === 'pending'}
                        empty={tableData.length === 0}
                    >
                        <StyledTableTabSection>
                            {/* Tab List */}
                            <Table.TabsWrapper>
                                <Table.TabList />
                            </Table.TabsWrapper>
                            <Table.TableSearchWrapper>
                                {/* Table data export */}
                                <Table.TableExport
                                    exportParams={exportParams!}
                                    totalResults={(tableTotalData as DataTableMetaObject)?.total_count}
                                    allDataExport={allDataExport}
                                />
                                {/* Table Search */}
                                <Table.TableSearch
                                    value={searchValue}
                                    onChange={handleTableSearchOnChange}
                                    onClear={handleTableSearchClearOnClick}
                                />
                            </Table.TableSearchWrapper>
                        </StyledTableTabSection>
                        <Table.TableWrapper>
                            <Table.Header>
                                <Table.Row key="thead">
                                    {columns.map(column => (
                                        <Table.CellHeader
                                            key={column.id}
                                            column={column}
                                            onClick={() => {
                                                handleSetCurrentOrder(column);
                                                handelResetSelectedRows();
                                            }}
                                        />
                                    ))}
                                </Table.Row>

                                {hideTotals ? null : (
                                    <Table.RowTotal key="total-row">
                                        {columns.map(column => {
                                            const total = getColumnTotal(tableTotalData as DataTableMetaObject, column);

                                            return (
                                                <Table.CellTotal
                                                    key={column.id}
                                                    id={column.id}
                                                    isDimension={column?.isDimension}
                                                    column={column!}
                                                >
                                                    {total ? total : ''}
                                                </Table.CellTotal>
                                            );
                                        })}
                                    </Table.RowTotal>
                                )}
                            </Table.Header>

                            <Table.Body>
                                {tableData.map(data => (
                                    <GenericTableRow
                                        key={data.__id}
                                        data={data}
                                        columns={columns}
                                        handleCheckboxChange={handleCheckboxChange}
                                        handleDimensionClick={handleDimensionClick}
                                    />
                                ))}
                            </Table.Body>
                        </Table.TableWrapper>

                        {/* Paginations */}
                        <Table.Pagination>
                            <Table.Description>
                                <span data-testid="pagination-total">
                                    {tablePagination.currentRange[0]} to{' '}
                                    {Math.min(tablePagination.currentRange[1], tablePagination.totalResults)} of{' '}
                                    {tablePagination.totalResults} results. Showing{' '}
                                </span>
                                <Table.Dropdown>
                                    <Dropdown
                                        selectedRowCount={tablePagination.rowDefault}
                                        defaultValue={tablePagination.rowDefault}
                                        options={tablePagination.rowOptions}
                                        onSelect={event => {
                                            handlePageRowOptionsClick(Number(event.target.value));
                                            handelResetSelectedRows();
                                        }}
                                        indexId={0}
                                        legendStyle="solid"
                                        showTitle={false}
                                        title=""
                                    />
                                </Table.Dropdown>
                                <span data-testid="pagination-rows">rows per page.</span>
                            </Table.Description>
                            <Table.List>
                                <Table.IncreaseItem
                                    increment={-1}
                                    onClick={() => {
                                        handlePageIncreaseItemClick(-1);
                                        handelResetSelectedRows();
                                    }}
                                >
                                    <StyledPaginationLeftIcon />
                                </Table.IncreaseItem>
                                {tablePaginationsList.map(page => (
                                    <Table.ListItems
                                        onClick={() => {
                                            handlePageListItemClick(page.pageNumber);
                                            handelResetSelectedRows();
                                        }}
                                        isCurrent={page.isCurrent}
                                        disabled={false}
                                    >
                                        {page.pageNumber || '...'}
                                    </Table.ListItems>
                                ))}
                                <Table.IncreaseItem
                                    increment={1}
                                    onClick={() => {
                                        handlePageIncreaseItemClick(1);
                                        handelResetSelectedRows();
                                    }}
                                >
                                    <StyledPaginationRightIcon />
                                </Table.IncreaseItem>
                            </Table.List>
                        </Table.Pagination>
                    </Table>
                </StyledTableSection>
            </StyledLayout>
        );
    }

    return null;
};

type ReportViewLayoutWrapperProps = {
    config: ReportConfig;
    tabKey?: number;
    selectedProducts: [];
    startDate: Moment;
    endDate: Moment;
    selectedMetrics: [];
};

const ReportViewLayoutWrapper = ({
    config,
    selectedProducts,
    startDate,
    endDate,
    selectedMetrics,
    tabKey = 0,
}: ReportViewLayoutWrapperProps) => {
    return (
        <ReportViewProvider
            defaultTabKey={tabKey}
            config={config}
            selectedProducts={selectedProducts}
            startDate={startDate}
            endDate={endDate}
            selectedMetrics={selectedMetrics}
        >
            <ReportViewLayout />
        </ReportViewProvider>
    );
};

export default ReportViewLayoutWrapper;
