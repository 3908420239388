import React from 'react';
import styled from 'styled-components';

// Styles
const StyledDropdownLegend = styled.div`
    display: inline-block;
    width: 10%;
    padding-top: 15px;
`;

const StyledDropdownLegendRule = styled.hr<{ legendStyle: 'solid' | 'dashed' }>`
    border-top: 2px ${props => (props.legendStyle === 'dashed' ? 'dashed' : 'solid')};
    border-bottom: ${props => props.legendStyle === 'dashed' && 0};
`;

type DropdownLegendProps = {
    legendStyle: 'solid' | 'dashed';
};

const DropdownLegend = ({ legendStyle }: DropdownLegendProps) => {
    return (
        <StyledDropdownLegend>
            <StyledDropdownLegendRule legendStyle={legendStyle} />
        </StyledDropdownLegend>
    );
};

export default DropdownLegend;
