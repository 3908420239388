import React from 'react';

// Components
import Dropdown from './components/dropdown';
import DropdownTitle from './components/dropdown-title';
import DropdownLegend from './components/dropdown-legend';
import DropdownList from './components/dropdown-list';
import DropdownListItems from './components/dropdown-list-items';

type GraphMetricsDropdownProps = {
    children: React.ReactNode;
};

const GraphMetricsDropdown = ({ children }: GraphMetricsDropdownProps) => {
    return <>{children}</>;
};

GraphMetricsDropdown.Dropdown = Dropdown;
GraphMetricsDropdown.DropdownTitle = DropdownTitle;
GraphMetricsDropdown.DropdownLegend = DropdownLegend;
GraphMetricsDropdown.DropdownList = DropdownList;
GraphMetricsDropdown.DropdownListItems = DropdownListItems;

export default GraphMetricsDropdown;
