import React from 'react';
import styled from 'styled-components';

// Styles
const StyledSelectionList = styled.ul`
    list-style: none;
    font-size: 14px;
    max-height: 310px;
    overflow-y: auto;
`;

type LegendItemProps = {
    children: React.ReactNode;
};

const LegendItem = ({ children }: LegendItemProps) => {
    return <StyledSelectionList>{children}</StyledSelectionList>;
};

export default LegendItem;
