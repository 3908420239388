import React from 'react';
import { QueryStatus } from '@tanstack/react-query';

// Context
import { GraphProvider } from './context/graph-context';

// Components
import TabList from './components/tab/tab-list';
import TabListItem from './components/tab/tab-list-item';
import GraphWrapper from './components/graph-wrapper';
import LineGraph from './components/line-graph';
import TrendLineGraph from './components/trend-line-graph';
import BarGraph from './components/bar-graph';
import PieGraph from './components/pie-graph';
import DoughnutGraph from './components/doughnut-graph';
import FilterWrapper from './components/filter/filter-wrapper';
import FilterTitle from './components/filter/filter-title';
import FilterRadioGroup from './components/filter/filter-radio-group';
import FilterRadio from './components/filter/filter-radio';
import FilterCheckbox from './components/filter/filter-check-box';

// Types
import { AnnotationConfig, ChartType } from '../../types';
import { CubedField } from '../../../types';

type GraphProps = {
    status: QueryStatus | string;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
    startDate: string;
    endDate: string;
    chartXMetric: CubedField;
    selectedChartType: ChartType;
    annotations: AnnotationConfig;
    handleChangeAnnotationVisibility: (id: string) => void;
    children: React.ReactNode;
};

const Graph = ({
    status,
    isFetching,
    disabled,
    empty,
    startDate,
    endDate,
    chartXMetric,
    selectedChartType,
    annotations,
    handleChangeAnnotationVisibility,
    children,
}: GraphProps) => {
    return (
        <GraphProvider
            status={status}
            isFetching={isFetching}
            disabled={disabled}
            empty={empty}
            startDate={startDate}
            endDate={endDate}
            chartXMetric={chartXMetric}
            selectedChartType={selectedChartType}
            annotations={annotations}
            handleChangeAnnotationVisibility={handleChangeAnnotationVisibility}
        >
            {children}
        </GraphProvider>
    );
};

// Tabs
Graph.TabList = TabList;
Graph.TabListItem = TabListItem;
// Graphs
Graph.GraphWrapper = GraphWrapper;
Graph.LineGraph = LineGraph;
Graph.TrendLineGraph = TrendLineGraph;
Graph.BarGraph = BarGraph;
Graph.PieGraph = PieGraph;
Graph.DoughnutGraph = DoughnutGraph;
// Filter
Graph.FilterWrapper = FilterWrapper;
Graph.FilterTitle = FilterTitle;
Graph.FilterRadioGroup = FilterRadioGroup;
Graph.FilterRadio = FilterRadio;
Graph.FilterCheckbox = FilterCheckbox;

export default Graph;
