import moment, { Moment } from 'moment';

// Redux
import store from '../../redux/store';

// Enums
import dataTypes from '../../filter-bar/enums/data-types';
import { ChartDateGranularity } from '../../configurations/common/chart-types';

// Helpera
import { generateTableParams } from './table-helpers';
import { formatDimensionFilters } from '../../helpers/dimension/dimension-helper';
import { channelColours } from '../../helpers/colours';

// Types
import {
    BarChartData,
    ChartConfig,
    ChartDateGranularityTypes,
    ChartSeriesData,
    DataTableSelectedRows,
    DimensionFilters,
    OrderByDir,
    ReportConfig,
} from '../types';
import { CubedField, CubedResource } from '../../types';
import { MetricsFilter, Product } from '../../filter-bar/types';

export type generateGraphParamsArgs = {
    config: ReportConfig;
    resource: CubedResource;
    tableGroupBy: CubedField[];
    xMetric: CubedField | null;
    yMetrics: CubedField[];
    startDate: Moment;
    endDate: Moment;
    chartDateGranularity: ChartDateGranularityTypes;
};

export const generateGraphParams = ({
    config,
    resource,
    tableGroupBy,
    xMetric,
    yMetrics,
    startDate,
    endDate,
    chartDateGranularity,
}: generateGraphParamsArgs) => {
    let timeframe;
    const duration = Math.round(moment.duration(endDate.diff(startDate)).asDays());

    if (xMetric?.dataType === dataTypes.DATE_TIME) {
        timeframe = { key: 'graph_group_by_type', value: `${resource.dateDimension.rawName}__${chartDateGranularity}` };
    }

    if (xMetric?.dataType === dataTypes.DATE) {
        if (chartDateGranularity === ChartDateGranularity.Day || chartDateGranularity === ChartDateGranularity.Month) {
            timeframe = {
                key: 'graph_group_by_type',
                value: `${resource.dateDimension.rawName}__${chartDateGranularity}`,
            };
        }
    }

    const groupby = { key: 'graph_group_by', value: xMetric?.rawName };
    const orderby = { key: 'order_by', value: xMetric?.rawName };

    const graphMetrics = yMetrics.map(yMetric => yMetric.rawName);
    const graphMetric = { key: 'graph_metrics', value: graphMetrics.join(',') };

    if (resource.graphGroupByOverride) {
        groupby.value = resource.graphGroupByOverride?.map(value => value?.rawName).join();
    }

    if (resource.graphOrderByOverride) {
        orderby.value = resource.graphOrderByOverride.rawName;
    }

    const params = [
        groupby,
        { key: 'group_by', value: tableGroupBy[0].rawName },
        { key: 'graph', value: true },
        { key: 'pagination', value: false },
        { key: 'limit', value: duration === 1 ? 24 : duration },
        orderby,
        graphMetric,
        { key: 'totals', value: config.chart?.showTotals ? config.chart?.showTotals : false },
    ];

    timeframe && params.push(timeframe);

    return params;
};

type generateGraphRequestsArgs = {
    config: ReportConfig;
    dimensionFilters: DimensionFilters[];
    page: number;
    resource: CubedResource;
    rowCount: number;
    searchValue: string;
    selectedProducts: Product[];
    selectedMetrics: MetricsFilter[];
    selectedRows: DataTableSelectedRows[];
    tableGroupBy: CubedField[];
    tableOrderBy: CubedField;
    tableOrderDir: OrderByDir;
    xMetric: CubedField | null;
    yMetrics: CubedField[];
    startDate: Moment;
    endDate: Moment;
    chartDateGranularity: ChartDateGranularityTypes;
};

export const generateGraphRequests = ({
    config,
    dimensionFilters,
    page,
    resource,
    rowCount,
    searchValue,
    selectedProducts,
    selectedMetrics,
    selectedRows,
    tableGroupBy,
    tableOrderBy,
    tableOrderDir,
    xMetric,
    yMetrics,
    startDate,
    endDate,
    chartDateGranularity,
}: generateGraphRequestsArgs) => {
    const requests = [];

    let graphParams = generateGraphParams({
        config,
        resource,
        tableGroupBy,
        xMetric,
        yMetrics,
        startDate,
        endDate,
        chartDateGranularity,
    });

    if (selectedRows.length < 1) {
        let params = mergeParams(
            [
                ...generateTableParams({
                    config,
                    dimensionFilters,
                    page,
                    resource,
                    rowCount,
                    searchValue,
                    selectedProducts,
                    tableGroupBy,
                    tableOrderBy,
                    tableOrderDir,
                    startDate,
                    endDate,
                    selectedMetrics,
                }),
            ],
            [...graphParams]
        );

        if (config.chart.chartType === 'network') {
            params = params.filter(param => !Object.values(param).includes('limit'));
        }

        if (config.chart.extraParams) {
            config.chart.extraParams.forEach(param => {
                params.push(param);
            });
        }

        requests.push({
            resource,
            params: params,
            filter: 'all',
            name: 'all',
            colour: channelColours[6].colour,
        });
    } else {
        let filters = formatDimensionFilters(dimensionFilters, resource);
        graphParams = graphParams.concat(filters);

        for (let row of selectedRows) {
            let field = tableGroupBy[0];
            // graph should use raw_value to display metrics if available, otherwise use value
            let data = row.data[field.rawName];

            let value = data.raw_value !== undefined ? data.raw_value : data.value;

            let key = `${field.rawName}${field.lookupTerm ? '__' + field.lookupTerm : ''}`;

            let params = mergeParams(
                [
                    ...generateTableParams({
                        config,
                        dimensionFilters,
                        page,
                        resource,
                        rowCount,
                        searchValue,
                        selectedProducts,
                        tableGroupBy,
                        tableOrderBy,
                        tableOrderDir,
                        startDate,
                        endDate,
                        selectedMetrics,
                    }),
                ],
                [...graphParams, { key: key, value: value }]
            );

            if (config.chart.chartType === 'network') {
                params = params.filter(param => !Object.values(param).includes('limit'));
            }

            requests.push({
                resource,
                params: params,
                row: row,
                colour: row.colour,
                filter: `${key}=${value}`,
                name: `${value}`,
            });
        }
    }

    return requests;
};

export type Params = {
    key: string;
    value?: string | number | boolean | string[];
};

export const mergeParams = (prevParams: Params[], newParams: Params[]) => {
    const updatedParams = [...prevParams];
    for (let newParam of newParams) {
        const exists = prevParams.some(prevParam => prevParam.key === newParam.key);
        for (let prevParam of prevParams) {
            if (prevParam.key === newParam.key) {
                prevParam.value = newParam.value;
            }
        }
        if (!exists) {
            updatedParams.push(newParam);
        }
    }

    return updatedParams;
};

export type SetTickIntervalArgs = {
    chart: ChartConfig;
    xMetric: CubedField;
    data: ChartSeriesData[];
    chartDateGranularity: ChartDateGranularityTypes;
    startDate: Moment;
    endDate: Moment;
};

export const setTickInterval = ({
    chart,
    xMetric,
    data,
    chartDateGranularity,
    startDate,
    endDate,
}: SetTickIntervalArgs): number => {
    if (chart?.setTickIntervalOverride) {
        return chart.setTickIntervalOverride(xMetric) as number;
    }

    if (data.length < 1) return 0;

    const dateDiff = Math.round(moment.duration(endDate.diff(startDate)).asDays());
    const day = 24 * 3600 * 1000;
    const hour = 2 * 3600 * 1000;
    const month = 30 * 24 * 3600 * 1000;

    if (chartDateGranularity === ChartDateGranularity.Hour) {
        return hour;
    } else if (chartDateGranularity === ChartDateGranularity.Month) {
        return month;
    } else if (dateDiff < 30) {
        return day;
    } else {
        return day * Math.ceil(dateDiff / 30);
    }
};

export const updateChartTooltipPointFormat = (chart: ChartConfig, row: string | number | string[]) => {
    const currencySymbol = store.getState().currency.symbol;

    const chartConfig = {
        ...chart,
        options: {
            ...chart.options,
            tooltip: {
                ...chart.options?.tooltip,
                pointFormat: (row as string).includes('%')
                    ? '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%<br/>'
                    : (row as string).includes(currencySymbol)
                    ? `<span style="color:{point.color}">{point.name}</span>: <b>${currencySymbol}{point.y:.2f}<br/>`
                    : '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}<br/>',
            },
            plotOptions: {
                ...chart.options?.plotOptions,
                series: {
                    ...chart.options?.plotOptions?.series,
                    dataLabels: {
                        ...chart.options?.plotOptions?.series?.dataLabels,
                        format: (row as string).includes('%')
                            ? '{point.y:.2f}%'
                            : (row as string).includes(currencySymbol)
                            ? currencySymbol + '{point.y:.2f}'
                            : '{point.y:.2f}',
                    },
                },
            },
        },
    };

    return chartConfig;
};

export const sortChartData = (row: BarChartData, prevRow: BarChartData) => {
    if (row['y'] === prevRow['y']) {
        return 0;
    } else {
        return row['y'] > prevRow['y'] ? -1 : 1;
    }
};
