import React, { createContext, useContext } from 'react';
import { QueryStatus } from '@tanstack/react-query';

// Hooks
import UseComponentVisible from '../../../../hooks/use-component-visible';

export type BreadcrumbContextValues = {
    status: QueryStatus;
    isFetching: boolean;
    tableDisabled: boolean;
    empty: boolean;
    fieldOptionsRef: React.MutableRefObject<null>;
    expendFieldOptions: boolean;
    setExpendFieldOptions: React.Dispatch<React.SetStateAction<boolean>>;
};

const BreadcrumbContext = createContext<BreadcrumbContextValues | undefined>(undefined);

export const useBreadcrumbContext = () => {
    const context = useContext(BreadcrumbContext);
    if (context === undefined) {
        throw new Error('"Breadcrumb" child components cannot be rendered outside of the Breadcrumb component');
    }

    return context;
};

type BreadcrumbProviderProps = {
    status: QueryStatus;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
    children: React.ReactNode;
};

export const BreadcrumbProvider = ({ status, isFetching, disabled, empty, children }: BreadcrumbProviderProps) => {
    const {
        ref: fieldOptionsRef,
        isComponentVisible: expendFieldOptions,
        setIsComponentVisible: setExpendFieldOptions,
    } = UseComponentVisible(false);

    return (
        <BreadcrumbContext.Provider
            value={{
                status,
                isFetching,
                tableDisabled: disabled,
                empty,
                fieldOptionsRef,
                expendFieldOptions,
                setExpendFieldOptions,
            }}
        >
            {children}
        </BreadcrumbContext.Provider>
    );
};
