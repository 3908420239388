import React from 'react';
import styled from 'styled-components';

import { useTableContext } from '../../context/table-context';

// Styles
const StyledPaginationList = styled.ul`
    list-style: none;
    display: inline-box;
    position: relative;
`;

// Types
type ListProps = {
    children: React.ReactNode;
};

const List = ({ children }: ListProps) => {
    const { status } = useTableContext();

    if (status === 'success') {
        return <StyledPaginationList>{children}</StyledPaginationList>;
    }

    return null;
};

export default List;
