import React from 'react';
import styled from 'styled-components';

// Styles
const StyledBreadcrumbsItem = styled.div`
    position: relative;
    box-sizing: border-box;
    float: right;
    margin-left: -20px;

    @media (max-width: 768px) {
        margin-left: 0;
        margin-top: -13px;
    }
`;

type DimensionSelectBoxProps = {
    children: React.ReactNode;
};

const DimensionSelectBox = ({ children }: DimensionSelectBoxProps) => {
    return <StyledBreadcrumbsItem>{children}</StyledBreadcrumbsItem>;
};

export default DimensionSelectBox;
