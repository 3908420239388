import React from 'react';
import { Moment } from 'moment';

// Types
import { DimensionFilters } from '../../../types';
import { CubedField, CubedResource } from '../../../../types';

// Context
import { BreadcrumbDimensionSearchProvider } from '../context/breadcrumb-dimension-search-context';

// Components
import DimensionSearchBoxContent from './dimension-search-box-content';
import DimensionSearchBoxContentItem from './dimension-search-box-content-item';
import DimensionSearchBoxOptions from './dimension-search-box-options';

type DimensionSearchBoxProps = {
    filter: DimensionFilters;
    startDate: Moment;
    endDate: Moment;
    dimensionFilters: DimensionFilters[];
    resource: CubedResource;
    setDimensionFilters: (filters: DimensionFilters[]) => void;
    setTableGroupBy: (field: CubedField[]) => void;
    handleRemoveFilter: (field: CubedField) => void;
    children?: React.ReactNode;
};

const DimensionSearchBox = ({
    filter,
    startDate,
    endDate,
    dimensionFilters,
    resource,
    setDimensionFilters,
    setTableGroupBy,
    handleRemoveFilter,
    children,
}: DimensionSearchBoxProps) => {
    return (
        <BreadcrumbDimensionSearchProvider
            filter={filter}
            startDate={startDate}
            endDate={endDate}
            dimensionFilters={dimensionFilters}
            resource={resource}
            setDimensionFilters={setDimensionFilters}
            setTableGroupBy={setTableGroupBy}
            handleRemoveFilter={handleRemoveFilter}
        >
            {children}
        </BreadcrumbDimensionSearchProvider>
    );
};

DimensionSearchBox.DimensionSearchBoxContent = DimensionSearchBoxContent;
DimensionSearchBox.DimensionSearchBoxContentItem = DimensionSearchBoxContentItem;
DimensionSearchBox.DimensionSearchBoxOptions = DimensionSearchBoxOptions;

export default DimensionSearchBox;
