import React from 'react';
import styled from 'styled-components';

// Styles
const StyledBreadcrumbsItemContentSpan = styled.span`
    font-weight: 300;
    display: inline-block;
    text-transform: uppercase;
`;

type DimensionSelectBoxContentItemProps = {
    children: React.ReactNode;
};

const DimensionSelectBoxContentItem = ({ children }: DimensionSelectBoxContentItemProps) => {
    return (
        <StyledBreadcrumbsItemContentSpan data-testid="breadcrumb-item-content">
            {children}
        </StyledBreadcrumbsItemContentSpan>
    );
};

export default DimensionSelectBoxContentItem;
