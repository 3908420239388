import React from 'react';
import { QueryStatus } from '@tanstack/react-query';

import { BreadcrumbProvider } from './context/breadcrumb-context';

import Home from './components/home';
import DimensionSelectBox from './components/dimension-select-box';
import Placeholder from './components/placeholder';
import DimensionSelectBoxContent from './components/dimension-select-box-content';
import DimensionSelectBoxOptions from './components/dimension-select-box-options';
import DimensionSelectBoxContentItem from './components/dimension-select-box-content-item';
import DimensionSelectBoxContentDropdownIcon from './components/dimension-select-box-content-dropdown-icon';
import DimensionSelectBoxOptionsResources from './components/dimension-select-box-options-resources';
import DimensionSelectBoxOptionsFields from './components/dimension-select-box-options-fields';
import DimensionSearchBox from './components/dimension-search-box';

export type BreadcrumbProps = {
    status: QueryStatus;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
    children: React.ReactNode;
};

const Breadcrumb = ({ status, isFetching, disabled, empty, children }: BreadcrumbProps) => {
    return (
        <BreadcrumbProvider status={status} isFetching={isFetching} disabled={disabled} empty={empty}>
            {children}
        </BreadcrumbProvider>
    );
};

Breadcrumb.Home = Home;
Breadcrumb.DimensionSelectBox = DimensionSelectBox;
Breadcrumb.Placeholder = Placeholder;
Breadcrumb.DimensionSelectBoxContent = DimensionSelectBoxContent;
Breadcrumb.DimensionSelectBoxContentItem = DimensionSelectBoxContentItem;
Breadcrumb.DimensionSelectBoxContentDropdownIcon = DimensionSelectBoxContentDropdownIcon;
Breadcrumb.DimensionSelectBoxOptions = DimensionSelectBoxOptions;
Breadcrumb.DimensionSelectBoxOptionsResources = DimensionSelectBoxOptionsResources;
Breadcrumb.DimensionSelectBoxOptionsFields = DimensionSelectBoxOptionsFields;
Breadcrumb.DimensionSearchBox = DimensionSearchBox;

export default Breadcrumb;
