import { keepPreviousData, useQueries } from '@tanstack/react-query';

// Query functions
import fetchResourceReportView from '../query-functions/fetch-resource-report-view';

// Types
import { Params } from '../../section-dashboard/types';
import { CubedConfigResource, CubedResource } from '../../types';
import { ResourceDataSuccess } from '../types';

// Helpers
import generateQueryKey from '../helpers/generate-query-key';

type UseFetchResourcesReportViewArgs<TResponse, TSelectResponse> = {
    resource: CubedResource | CubedConfigResource;
    params?: Params;
    staleTime?: number;
    enabled?: boolean;
    isPaginated?: boolean;
    select?: (data: TResponse) => TSelectResponse;
}[];

const useFetchResourcesReportView = <TResponse = ResourceDataSuccess, TSelectResponse = TResponse>(
    resources: UseFetchResourcesReportViewArgs<TResponse, TSelectResponse>
) => {
    const responses = useQueries({
        queries: resources.map(res => {
            if (typeof res.enabled === 'boolean') {
                res.enabled = res.enabled && !!res.resource;
            }

            return {
                queryKey: generateQueryKey({ resource: res.resource, params: res.params || [] }),
                queryFn: fetchResourceReportView,
                placeholderData: res.isPaginated ? keepPreviousData : undefined,
                staleTime: res.staleTime || 1000 * 60 * 10,
                enabled: res.enabled,
                select: res.select,
            };
        }),
        combine: responses => {
            return {
                data: responses.map(response => response.data),
                status: responses.every(response => response.status === 'success')
                    ? 'success'
                    : responses.some(response => response.status === 'error')
                    ? 'error'
                    : 'pending',
                success: responses.every(response => response.status === 'success'),
                pending: responses.some(response => response.isPending),
                error: responses.some(response => response.isError),
            };
        },
    });

    return responses;
};

export default useFetchResourcesReportView;
