/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import moment, { Moment } from 'moment';

import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers/core';

// Helpers
import { formatSalesTitle } from '../../helpers/formatGlobalSaleTitle';
import { generateTablePaginationsList } from '../helpers/table-helpers';

// Types
import {
    AnnotationConfig,
    AnnotationResponseItem,
    ChartDateGranularityTypes,
    ChartType,
    DataTableColumn,
    DataTablePagination,
    DataTablePaginationListItem,
    MenuRadioOption,
    OrderByDir,
    ReportConfig,
} from '../types';
import { CubedAnnotationResource, CubedField } from '../../types';
import { MetricsFilter, Product } from '../../filter-bar/types';

// Configurations
import { ChartDateGranularity, ChartOptions, ChartSelectionLimit } from '../../configurations/common/chart-types';

// React Query
import useFetchResourcesReportView from '../../react-query/hooks/use-fetch-resources-report-view';
import { ConfigDataSuccess } from '../../react-query/types';

export type TabListObject = {
    displayName: string;
    id: number;
    isSelected: boolean;
    key: number;
    linkedResourceKey: number;
    tabName: string;
};

type ReportViewContextProps = {
    config: ReportConfig;
    modifiedConfig: ReportConfig;
    selectedProducts: Product[];
    selectedMetrics: MetricsFilter[];
    startDate: Moment;
    endDate: Moment;
    tabs: TabListObject[];
    setTabs: React.Dispatch<React.SetStateAction<TabListObject[]>>;
    selectTab: (id: number) => void;
    tableOrderBy: CubedField;
    setTableOrderBy: React.Dispatch<React.SetStateAction<CubedField>>;
    tableOrderDir: OrderByDir;
    setTableOrderDir: React.Dispatch<React.SetStateAction<OrderByDir>>;
    handleSetCurrentOrder: (column: DataTableColumn) => void;
    scrollPosX: number;
    handleOnScroll: (event: React.SyntheticEvent<HTMLDivElement>) => void;
    initialPaginations: DataTablePagination;
    initialPaginationsList: DataTablePaginationListItem[];
    tablePagination: DataTablePagination;
    setTablePagination: React.Dispatch<React.SetStateAction<DataTablePagination>>;
    tablePaginationsList: DataTablePaginationListItem[];
    setTablePaginationsList: React.Dispatch<React.SetStateAction<DataTablePaginationListItem[]>>;
    handlePageRowOptionsClick: (value: number) => void;
    handlePageListItemClick: (pageNumber: number) => void;
    handlePageIncreaseItemClick: (increment: number) => void;
    selectedChartType: ChartType;
    setSelectedChartType: React.Dispatch<React.SetStateAction<ChartType>>;
    handleChartTypeSelection: (type: ChartType) => void;
    dateGranularityRadioOptions: MenuRadioOption[];
    annotationConfig: CubedAnnotationResource[];
    chartDateGranularity: ChartDateGranularityTypes;
    setChartDateGranularity: React.Dispatch<React.SetStateAction<ChartDateGranularityTypes>>;
    annotations: AnnotationConfig;
    setAnnotations: React.Dispatch<React.SetStateAction<AnnotationConfig>>;
    annotationResponse: AnnotationResponseItem[];
    setAnnotationResponse: React.Dispatch<React.SetStateAction<AnnotationResponseItem[]>>;
};

const ReportViewContext = createContext<ReportViewContextProps | undefined>(undefined);

type ReportViewProviderProps = {
    defaultTabKey: number;
    config: ReportConfig;
    selectedProducts: Product[];
    selectedMetrics: MetricsFilter[];
    startDate: Moment;
    endDate: Moment;
    children: React.ReactNode;
};

export const ReportViewProvider = ({
    defaultTabKey,
    config,
    selectedProducts,
    selectedMetrics,
    startDate,
    endDate,
    children,
}: ReportViewProviderProps) => {
    const globalSaleTitle = useSelector((state: RootState) => state.saleTitle.globalSalesTitle);

    const {
        table: { tabListItems },
        resources,
        pagination,
        chart,
    } = config;

    const initialPaginations = { ...pagination };
    const initialPaginationsList = [
        {
            pageNumber: 1,
            isCurrent: true,
        },
    ];

    const dateGranularityRadioOptions = [
        { label: 'By Day', value: 'day' },
        { label: 'By Month', value: 'month' },
    ];

    // Modified config
    const [modifiedConfig, setModifiedConfig] = useState<ReportConfig>(config);

    // Tab states
    const [tabs, setTabs] = useState<TabListObject[]>(
        tabListItems.map((conf, index) => {
            return {
                key: index,
                id: index,
                linkedResourceKey: index,
                tabName: conf.tabName,
                displayName: formatSalesTitle(conf.displayName, globalSaleTitle),
                isSelected: index === defaultTabKey,
            };
        })
    );

    // Table states
    const [scrollPosX, setScrollPosX] = useState(0);
    const [tableOrderBy, setTableOrderBy] = useState<CubedField>(resources[0].defaultOrderBy as CubedField);
    const [tableOrderDir, setTableOrderDir] = useState<OrderByDir>(
        resources[0].defaultOrderBy?.defaultOrderDir as OrderByDir
    );

    // Table Pagination state
    const [tablePagination, setTablePagination] = useState<DataTablePagination>(initialPaginations);
    const [tablePaginationsList, setTablePaginationsList] =
        useState<DataTablePaginationListItem[]>(initialPaginationsList);

    // Chart / Graph states
    const [selectedChartType, setSelectedChartType] = useState<ChartType>(
        (chart?.chartType as ChartType) || (chart?.allowedChartTypes[0] as ChartType) || undefined
    );
    const chartOptions = ChartOptions;
    const chartSelectionLimit = ChartSelectionLimit;

    // Granularity state
    const [chartDateGranularity, setChartDateGranularity] = useState<ChartDateGranularityTypes>(
        ChartDateGranularity.Day as ChartDateGranularityTypes
    );

    // Annotations state
    const annotationConfig = chart?.annotations || [];
    const memoizedAnnotationConfig = useMemo(() => annotationConfig, [annotationConfig]);
    const [annotations, setAnnotations] = useState<AnnotationConfig>(
        memoizedAnnotationConfig
            ? memoizedAnnotationConfig.map(configItem => {
                  return {
                      name: configItem.displayName,
                      id: configItem.id,
                      checked: false,
                      disabled: true,
                      data: [],
                  };
              })
            : []
    );
    const [annotationResponse, setAnnotationResponse] = useState<AnnotationResponseItem[]>([]);

    // Fetch annotation data
    const annotationDataQuery = useFetchResourcesReportView(
        annotationConfig.map(request => ({
            resource: request,
            params: [
                { key: 'date__gte', value: startDate.format('YYYY-MM-DD') },
                { key: 'date_lte', value: endDate.format('YYYY-MM-DD') },
                { key: 'annotation_type', value: request.type },
            ],
            staleTime: 1000 * 60 * 10,
            enabled: true,
            isPaginated: false,
            select: (data: ConfigDataSuccess) => {
                return data;
            },
        }))
    );

    useEffect(() => {
        const chartType = (chart?.chartType as ChartType) || (chart?.allowedChartTypes[0] as ChartType);
        modifyChartConfigOption(chartType);
    }, []);

    useEffect(() => {
        const start = moment.utc(startDate.format('YYYY-MM-DD'));
        const end = moment.utc(endDate.format('YYYY-MM-DD'));

        if (start.isSame(end, 'date')) {
            setChartDateGranularity(ChartDateGranularity.Hour as ChartDateGranularityTypes);
        } else {
            setChartDateGranularity(ChartDateGranularity.Day as ChartDateGranularityTypes);
        }
    }, [startDate, endDate]);

    useEffect(() => {
        // if the chart date granularity is set to month, disable the annotations
        const updatedAnnotations = [...annotations].map(annotation => {
            return {
                ...annotation,
                checked: false,
                disabled: chartDateGranularity === ChartDateGranularity.Month ? true : false,
            };
        });

        setAnnotations(updatedAnnotations);
    }, [chartDateGranularity]);

    useEffect(() => {
        // if the chart date granularity is set to month, disable the annotations
        const updatedAnnotations = [...annotations].map(annotation => {
            return {
                ...annotation,
                checked: false,
                disabled: chartDateGranularity === ChartDateGranularity.Month ? true : false,
            };
        });

        setAnnotations(updatedAnnotations);
    }, [chartDateGranularity]);

    // Set annotation response
    useEffect(() => {
        if (annotationDataQuery.data) {
            const annotationResponseItems: AnnotationResponseItem[] = [];

            memoizedAnnotationConfig.forEach((config, index) => {
                const response = annotationDataQuery.data[index];

                if (response && response.objects) {
                    const annotationResponseItem: AnnotationResponseItem = {
                        id: config.id,
                        data: response.objects.map(dataItem => ({
                            name: dataItem.name,
                            date: dataItem.date,
                        })),
                    };
                    annotationResponseItems.push(annotationResponseItem);
                }
            });

            // Here memoizedAnnotationConfig is used to compare the previous state with the current state
            // for the performance optimization
            setAnnotationResponse(prevState => {
                const isEqual =
                    prevState.length === annotationResponseItems.length &&
                    prevState.every(
                        (item, index) =>
                            item.id === annotationResponseItems[index].id &&
                            item.data.length === annotationResponseItems[index].data.length &&
                            item.data.every(
                                (dataItem, dataIndex) =>
                                    dataItem.name === annotationResponseItems[index].data[dataIndex].name &&
                                    dataItem.date === annotationResponseItems[index].data[dataIndex].date
                            )
                    );

                if (!isEqual) {
                    return annotationResponseItems;
                }

                return prevState;
            });
        }
    }, [annotationDataQuery.data, memoizedAnnotationConfig]);

    // Handle tab selection
    const selectTab = (id: number) => {
        setTabs(prevTabs =>
            prevTabs.map(tab => (tab.id === id ? { ...tab, isSelected: true } : { ...tab, isSelected: false }))
        );
    };

    // Horizontal scroll
    const handleOnScroll = (event: React.SyntheticEvent<HTMLDivElement>) => {
        const scrollLeft = event.currentTarget.scrollLeft;
        setScrollPosX(scrollLeft);
    };

    // Sorting
    const handleSetCurrentOrder = (column: DataTableColumn) => {
        if (column.id === 'checkbox') return;

        const newOrderDir = tableOrderBy === column && tableOrderDir === 'asc' ? 'desc' : 'asc';
        setTableOrderBy(column);
        setTableOrderDir(newOrderDir);
    };

    // Pagination Page Row Options
    const handlePageRowOptionsClick = (value: number) => {
        setTablePagination({ ...tablePagination, currentPage: 1, rowDefault: value });
        setTablePaginationsList(
            generateTablePaginationsList(
                1,
                tablePagination.maxButtons,
                tablePagination.minPage,
                tablePagination.totalResults,
                value
            )
        );
    };

    // Pagination Page List Item
    const handlePageListItemClick = (pageNumber: number) => {
        setTablePagination({ ...tablePagination, currentPage: pageNumber });
        setTablePaginationsList(
            generateTablePaginationsList(
                pageNumber,
                tablePagination.maxButtons,
                tablePagination.minPage,
                tablePagination.totalResults,
                tablePagination.rowDefault
            )
        );
    };

    // Pagination Page Increase Item
    const handlePageIncreaseItemClick = (increment: number) => {
        const minPage = 1;
        const maxPage = Math.ceil(tablePagination.totalResults / tablePagination.rowDefault);
        const newPage = tablePagination.currentPage + increment;

        if (newPage < minPage || newPage > maxPage) {
            return;
        }

        setTablePagination({ ...tablePagination, currentPage: newPage });

        setTablePaginationsList(
            generateTablePaginationsList(
                newPage,
                tablePagination.maxButtons,
                tablePagination.minPage,
                tablePagination.totalResults,
                tablePagination.rowDefault
            )
        );
    };

    const modifyChartConfigOption = (chartType: ChartType) => {
        if (chartType === undefined) return;

        let options, selectionLimit;

        options =
            chart.options && (chart.options as Record<string, any>)[chartType]
                ? { ...(chart.options as Record<string, any>)[chartType]?.options }
                : { ...(chartOptions as Record<string, any>)[chartType]?.options };

        if ((chartType === 'line' || chartType === 'trendLine') && chart.skippedPlotNullValues) {
            options.plotOptions.series.connectNulls = chart.skippedPlotNullValues;
        }

        selectionLimit =
            chart.selectionLimit && typeof chart.selectionLimit === 'object' && chart.selectionLimit[chartType]
                ? chart.selectionLimit[chartType]
                : chartSelectionLimit[chartType as keyof typeof chartSelectionLimit];

        setModifiedConfig({
            ...config,
            chart: {
                ...chart,
                options: options,
                selectionLimit: selectionLimit,
                chartType: chartType,
            },
        });
    };

    // Handle chart type selection
    const handleChartTypeSelection = (chartType: ChartType) => {
        setSelectedChartType(chartType);
        modifyChartConfigOption(chartType);
    };

    return (
        <ReportViewContext.Provider
            value={{
                // Report
                config,
                modifiedConfig,
                selectedProducts,
                selectedMetrics,
                startDate,
                endDate,
                // Table Tabs
                tabs,
                setTabs,
                selectTab,
                // Tables
                tableOrderBy,
                setTableOrderBy,
                tableOrderDir,
                setTableOrderDir,
                handleSetCurrentOrder,
                // Table Scroll
                scrollPosX,
                handleOnScroll,
                // Table Paginations
                initialPaginations,
                initialPaginationsList,
                tablePagination,
                setTablePagination,
                tablePaginationsList,
                setTablePaginationsList,
                handlePageRowOptionsClick,
                handlePageListItemClick,
                handlePageIncreaseItemClick,
                // Chart Tabs
                selectedChartType,
                setSelectedChartType,
                handleChartTypeSelection,
                dateGranularityRadioOptions,
                // Chart Filteration
                annotationConfig,
                chartDateGranularity,
                setChartDateGranularity,
                annotations,
                setAnnotations,
                annotationResponse,
                setAnnotationResponse,
            }}
        >
            {children}
        </ReportViewContext.Provider>
    );
};

export const useReportViewContext = () => {
    const context = React.useContext(ReportViewContext);
    if (!context) {
        throw new Error('useReportView must be used within a ReportViewProvider');
    }

    return context;
};
