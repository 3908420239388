import React from 'react';
import styled from 'styled-components';

const StyledWrapper = styled.div`
    margin-left: auto;
    display: flex;
`;

// Types
export type TableSearchWrapperProps = {
    children: React.ReactNode;
};

const TableSearchWrapper = ({ children }: TableSearchWrapperProps) => {
    return <StyledWrapper>{children}</StyledWrapper>;
};

export default TableSearchWrapper;
