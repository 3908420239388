import React from 'react';
import styled from 'styled-components';

const StyledToolTipContainer = styled.div`
    position: relative;
    top: 33px;
    left: -48px;
    z-index: 3;
`;

const StyledToolTip = styled.div`
    position: absolute;
    left: 0px;
    top: 0px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.5;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 10px;
    display: inline-block;
`;

const StyledToolTipLabel = styled.div`
    max-width: 200px;
    padding: 3px 8px;
    color: ${({ theme }) => theme.colours.white};
    background-color: ${({ theme }) => theme.colours.offBlack};
    border-radius: 4px;
    font-size: 10px;
    text-align: center;
`;

type ToolTipProps = { label: string };

const ToolTip = ({ label }: ToolTipProps) => (
    <StyledToolTipContainer>
        <StyledToolTip>
            <StyledToolTipLabel>{label}</StyledToolTipLabel>
        </StyledToolTip>
    </StyledToolTipContainer>
);

export default ToolTip;
