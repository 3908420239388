// config for donut chart: https://www.highcharts.com/demo/pie-donut
// donut chart is a variation of a pie chart
export const donutChartConfig = {
    options: {
        credits: {
            enabled: false,
        },
        chart: {
            type: 'pie',
            events: {},
        },
        title: {
            text: '',
        },
        accessibility: {
            point: {
                valueSuffix: '%',
            },
        },
        plotOptions: {
            series: {
                animation: true,
            },
            pie: {
                shadow: false,
                center: ['50%', '50%'],
            },
        },
        tooltip: {
            valueSuffix: '%',
        },
        series: [
            {
                // innerLayer
            },
            {
                // outerLayer
                name: '',
                data: [],
                size: '100%',
                innerSize: '60%',
                dataLabels: {
                    formatter: function () {
                        return '<b>' + this.point.name + ':</b> ' + this.y + '%';
                    },
                },
                id: 'outerLayer',
            },
        ],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 400,
                    },
                    chartOptions: {
                        series: [
                            {},
                            {
                                id: 'outerLayer',
                                dataLabels: {
                                    enabled: false,
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
};
