import React from 'react';
import { QueryStatus } from '@tanstack/react-query';

// Context
import { TableProvider } from './context/table-context';

// Components
import TabsWrapper from './components/tab/tabs-wrapper';
import TabList from './components/tab/tab-list';
import TableWrapper from './components/table-wrapper';
import Header from './components/header';
import Row from './components/row';
import Body from './components/body';
import CellHeader from './components/cell/cell-header';
import CellBody from './components/cell/cell-body';
import CellCheckbox from './components/cell/cell-checkbox';
import CellTotal from './components/cell/cell-total';
import RowTotal from './components/row-total';
import Pagination from './components/pagination/pagination';
import Description from './components/pagination/description';
import Dropdown from './components/pagination/dropdown';
import List from './components/pagination/list';
import IncreaseItem from './components/pagination/increase-item';
import ListItems from './components/pagination/list-items';
import TableSearchWrapper from './components/table-search-wrapper';
import TableSearch from './components/table-search';
import TableExport from './components/table-export';

export type TableProps = {
    status: QueryStatus;
    children: React.ReactNode;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
};

const Table = ({ status, isFetching, disabled, empty, children }: TableProps) => {
    return (
        <TableProvider status={status} isFetching={isFetching} disabled={disabled} empty={empty}>
            {children}
        </TableProvider>
    );
};

// Tabs
Table.TabsWrapper = TabsWrapper;
Table.TabList = TabList;
// Table
Table.TableWrapper = TableWrapper;
Table.Header = Header;
Table.Row = Row;
Table.CellHeader = CellHeader;
Table.RowTotal = RowTotal;
Table.CellTotal = CellTotal;
Table.Body = Body;
Table.CellBody = CellBody;
Table.CellCheckbox = CellCheckbox;
// Pagination
Table.Pagination = Pagination;
Table.Description = Description;
Table.Dropdown = Dropdown;
Table.List = List;
Table.IncreaseItem = IncreaseItem;
Table.ListItems = ListItems;
// Table Search
Table.TableSearchWrapper = TableSearchWrapper;
Table.TableSearch = TableSearch;
// Table Export
Table.TableExport = TableExport;

export default Table;
