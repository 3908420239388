/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext } from 'react';
import { QueryStatus } from '@tanstack/react-query';

// Types
import { AnnotationConfig, ChartType } from '../../../types';
import { CubedField } from '../../../../types';

export type GraphContextValues = {
    status: QueryStatus | string;
    isFetching: boolean;
    grphDisabled: boolean;
    empty: boolean;
    startDate: string;
    endDate: string;
    chartXMetric: CubedField;
    selectedChartType: ChartType;
    annotations: AnnotationConfig;
    handleChangeAnnotationVisibility: (id: string) => void;
};

const GraphContext = createContext<GraphContextValues | undefined>(undefined);

export const useGraphContext = () => {
    const context = useContext(GraphContext);
    if (context === undefined) {
        throw new Error('"Graph" context cannot be rendered outside of the Graph component');
    }

    return context;
};

type GraphProviderProps = {
    status: QueryStatus | string;
    isFetching: boolean;
    disabled: boolean;
    empty: boolean;
    startDate: string;
    endDate: string;
    chartXMetric: CubedField;
    selectedChartType: ChartType;
    annotations: AnnotationConfig;
    handleChangeAnnotationVisibility: (id: string) => void;
    children: React.ReactNode;
};

export const GraphProvider = ({
    status,
    isFetching,
    disabled,
    empty,
    startDate,
    endDate,
    chartXMetric,
    selectedChartType,
    annotations,
    handleChangeAnnotationVisibility,
    children,
}: GraphProviderProps) => {
    return (
        <GraphContext.Provider
            value={{
                status,
                isFetching,
                grphDisabled: disabled,
                empty,
                startDate,
                endDate,
                chartXMetric,
                selectedChartType,
                annotations,
                handleChangeAnnotationVisibility,
            }}
        >
            {children}
        </GraphContext.Provider>
    );
};
