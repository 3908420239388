import React from 'react';
import styled from 'styled-components';

// Styles
const StyledDropdown = styled.div`
    display: inline-block;
    width: 100%;
    border: none;
    font-size: 14px;
`;

type DropdownProps = {
    children: React.ReactNode;
};

const Dropdown = ({ children }: DropdownProps) => {
    return <StyledDropdown>{children}</StyledDropdown>;
};

export default Dropdown;
