import React from 'react';
import styled from 'styled-components';

import { useTableContext } from '../context/table-context';

const StyledRow = styled.tr<{ selected?: boolean; isFetching: boolean; disabled: boolean }>`
    width: 100%;
    cursor: pointer;
    transition: 0.2s ease-in-out !important;
    position: relative;

    &:hover > td {
        background-color: ${props => !props.disabled && !props.isFetching && props.theme.colours.hoverGrey} !important;
    }

    & td {
        background-color: ${props => props.selected && props.theme.colours.selectionGrey};
    }
`;

type RowProps = {
    selected?: boolean;
    children: React.ReactNode;
};

const Row = ({ selected, children }: RowProps) => {
    const { status, isFetching, tableDisabled } = useTableContext();

    if (status) {
        return (
            <StyledRow
                selected={selected}
                isFetching={isFetching}
                disabled={tableDisabled}
                data-testid="data-table-row"
            >
                {children}
            </StyledRow>
        );
    }

    return null;
};

export default Row;
