import React from 'react';
import styled from 'styled-components';

// Components
import Cancel from '../../../../components/cancel';

// Types
import { DataTableSelectedRows } from '../../../types';

// Styles
const StyledCancelButton = styled.button`
    float: right;
    padding: 8px;
    background-color: ${props => props.theme.colours.white};
    border-style: none;
`;

type LegendItemListCancelProps = {
    rowId?: number | string | null;
    selectedRows: DataTableSelectedRows[];
    onRemoveLegend: (selectedRow: DataTableSelectedRows[], rowId?: number | string | null) => void;
};

const LegendItemListCancel = ({ rowId, selectedRows, onRemoveLegend }: LegendItemListCancelProps) => {
    return (
        <StyledCancelButton>
            <Cancel onClick={() => onRemoveLegend(selectedRows, rowId)} colour="red" floatRight={true} />
        </StyledCancelButton>
    );
};

export default LegendItemListCancel;
