import React from 'react';
import styled from 'styled-components';

import Cancel from '../../../../components/cancel';
import { useBreadcrumbDimensionSearchContext } from '../context/breadcrumb-dimension-search-context';

// Styles
const StyledBreadcrumbsItemContentWrapper = styled.div`
    border-radius: 0 37px 37px 0;
    background-color: ${props => props.theme.colours.white};
    padding: 6px;
    display: block;
    width: inherit;
    height: 100%;

    @media (max-width: 768px) {
        padding-left: 0;
    }
`;

const StyledBreadcrumbsItemContent = styled.div`
    background-color: ${props => props.theme.colours.lightGrey};
    line-height: 37px;
    height: 37px;
    min-width: 54px;
    padding-left: 24px;
    position: relative;
    border-radius: 0 37px 37px 0;
    overflow: hidden;
    box-shadow: ${props => props.theme.sectionDashboard.widget.base.shadow};

    @media (max-width: 768px) {
        padding-left: 0.5rem;
    }
`;

const StyledBreadcrumbsItemInut = styled.input`
    box-sizing: border-box;
    padding-left: 6px;
    margin-right: 37px;
    margin-top: 1px;
    line-height: 27px;
    font-weight: 300;
    color: ${props => props.theme.colours.darkGrey};
    border-radius: 0 37px 37px 0;
    background-color: transparent;
    transition: ${props => props.theme.transition};
    border: 1px solid transparent;
    max-width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
        background-color: rgba(255, 255, 255, 0.5);
    }
    &:focus {
        max-width: 260px;
        background-color: rgba(255, 255, 255, 0.6);
        border: 1px solid ${props => props.theme.colours.info};
    }

    @media (max-width: 1024px) {
        margin-right: 0;
    }
`;

const DimensionSearchBoxContentItem = () => {
    const { filter, currentFilter, handleOnFocus, handleOnBlur, handleOnClick, handleChange, handleRemoveFilter } =
        useBreadcrumbDimensionSearchContext();

    return (
        <StyledBreadcrumbsItemContentWrapper>
            <StyledBreadcrumbsItemContent>
                <StyledBreadcrumbsItemInut
                    onFocus={event => handleOnFocus(event)}
                    onBlur={() => handleOnBlur()}
                    onClick={() => handleOnClick()}
                    placeholder={currentFilter}
                    value={currentFilter}
                    onChange={handleChange}
                />

                <Cancel onClick={() => handleRemoveFilter(filter.field)} colour="red" floatRight={true} />
            </StyledBreadcrumbsItemContent>
        </StyledBreadcrumbsItemContentWrapper>
    );
};

export default DimensionSearchBoxContentItem;
